import React from "react";
import { useSelector } from "react-redux";
import { Trash, PencilSquare, CashCoin } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";

const InsuranceDisp = ({ payment, setShowModal, setType, setPayment }) => {
  const Navigate = useNavigate();
  const Creds = useSelector((state) => state.cred.creds);
  const Modes = useSelector((state) => state.mode.modes).filter(
    (mode) => parseInt(mode.deleted) === 0
  );
  const Ins = useSelector((state) => state.insComp.insComps).filter(
    (ins) => parseInt(ins.deleted) === 0
  );

  const FindUser = (linker) => {
    let cred = Creds.find((cred) => parseInt(cred.linker) == parseInt(linker));

    return cred || { firstname: "unknown" };
  };

  const Settlements = useSelector((state) => state.payment.payments)
    .filter(
      (record) =>
        parseInt(record.deleted) === 0 &&
        parseInt(record.insLinker) === parseInt(payment.linker)
    )
    .reduce((a, b) => +a + +b.amount, 0);

  const FindMode = (linker) => {
    let mode = Ins.find((mode) => parseInt(mode.linker) === parseInt(linker));

    return mode || { name: "unknown" };
  };

  return (
    <tr className="item">
      <td>{payment.name}</td>
      <td>{FindMode(payment.mode).name}</td>
      <td>
        {payment.code} {payment.details}
      </td>
      <td>{payment.amount - Settlements}</td>
      <td>
        {FindUser(payment.patientLinker).patientReg}{" "}
        {FindUser(payment.patientLinker).firstname}{" "}
        {FindUser(payment.patientLinker).lastname}
      </td>
      <td>{new Date(parseInt(payment.linker)).toLocaleDateString("en-US")}</td>
      <td>{FindUser(payment.credLinker).email}</td>
      <td>
        <PencilSquare
          onClick={() => {
            setShowModal(true);
            setType("edit");
            setPayment({ ...payment });
          }}
          style={{ cursor: "pointer" }}
          className="text-info mx-2"
        />

        <Trash
          onClick={() => {
            setShowModal(true);
            setType("delete");
            setPayment({ ...payment });
          }}
          style={{ cursor: "pointer" }}
          className="text-danger mx-2"
        />

        <CashCoin
          onClick={() => Navigate(`insurance/details/${payment.linker}`)}
          style={{ cursor: "pointer" }}
          className="mx-1 text-primary"
        />
      </td>
    </tr>
  );
};

export default InsuranceDisp;
