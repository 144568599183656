import React from "react";

const NoPayments = () => {
	return (
		<tbody>
			<tr>
				<th>No Payments</th>
				<td>
					Payments are financial receipts from patients eg consultation fee,
					admission fee etc
				</td>{" "}
				<td>
					<p className="text-primary">
						To add payments go to patients update patient
					</p>
				</td>
			</tr>
		</tbody>
	);
};

export default NoPayments;
