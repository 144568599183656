import swal from "sweetalert";
import {
  addPharmacyCat,
  fetchPharmacyCats,
  updatePharmacyCat,
} from "../reducers/PharmacyCatSlice";
import { ApiUrl } from "./Urls";

export const FetchPharmacyCats = async (
  token,
  instLinker,
  dispatch,
  online,
  from = "fetch",
  payload = {}
) => {
  if (from === "fetch") {
    FetchLivePharmacyCats(token, instLinker, online, dispatch);
  } else if (parseInt(instLinker) === parseInt(payload.instLinker)) {
    dispatch(updatePharmacyCat(payload));
  }
};

export const addLivePharmacyCat = (
  pharmacyCat,
  instLinker,
  token,
  dispatch
) => {
  return fetch(`${ApiUrl}/pharmacy-categories/add`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    },
    body: JSON.stringify({
      ...pharmacyCat,
      instLinker,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 201) {
        dispatch(updatePharmacyCat(res.pharmacyCat));
      } else {
        swal({
          title: "Failed to connect",
          icon: "warning",
          timer: 3000,
        });
      }
    })
    .catch(() => {
      swal({
        title: "Failed to connect",
        icon: "warning",
        timer: 3000,
      });
    });
};

export const updateLivePharmacyCat = (pharmacyCat, token, dispatch) => {
  return fetch(`${ApiUrl}/pharmacy-categories/edit`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    },
    body: JSON.stringify({
      ...pharmacyCat,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 200) {
        dispatch(updatePharmacyCat(res.pharmacyCat));
      } else {
        swal({
          title: "Failed to connect",
          icon: "warning",
          timer: 3000,
        });
      }
    })
    .catch(() => {
      swal({
        title: "Failed to connect",
        icon: "warning",
        timer: 3000,
      });
    });
};

export const FetchLivePharmacyCats = (token, instLinker, online, dispatch) => {
  fetch(`${ApiUrl}/pharmacy-categories/get`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    },
    body: JSON.stringify({
      online,
      instLinker,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 200) {
        dispatch(fetchPharmacyCats(res.pharmacyCats));
      }
    })
    .catch(() => {});
};
