import { configureStore } from "@reduxjs/toolkit";
import { LoadState, SaveState } from "./Utils/LocalStorage";
import AuthSlice from "./reducers/AuthSlice";
import CredSlice from "./reducers/CredSlice";
import InstSlice from "./reducers/InstSlice";
import ModeSlice from "./reducers/ModeSlice";
import ExpenseCatSlice from "./reducers/ExpenseCatSlice";
import ExpenseSlice from "./reducers/ExpenseSlice";
import ServiceCatSlice from "./reducers/ServiceCatSlice";
import ServiceSlice from "./reducers/ServiceSlice";
import WardSlice from "./reducers/WardSlice";
import BedSlice from "./reducers/BedSlice";
import InsCompSlice from "./reducers/InsCompSlice";
import PharmacyCatSlice from "./reducers/PharmacyCatSlice";
import ProductSlice from "./reducers/ProductSlice";
import EntrySlice from "./reducers/EntrySlice";
import HealthSlice from "./reducers/HealthSlice";
import VitalSlice from "./reducers/VitalSlice";
import InvoiceSlice from "./reducers/InvoiceSlice";
import PaymentSlice from "./reducers/PaymentSlice";
import AppointmentSlice from "./reducers/AppointmentSlice";
import StoreCatSlice from "./reducers/StoreCatSlice";
import ItemSlice from "./reducers/ItemSlice";
import StoreEntrySlice from "./reducers/StoreEntrySlice";
import SubSlice from "./reducers/SubSlice";
import OnlineSlice from "./reducers/OnlineSlice";
import PwaSlice from "./reducers/PwaSlice";
import ActiveSlice from "./reducers/ActiveSlice";
import PayrollEntrySlice from "./reducers/PayrollEntrySlice";
import PayrollCatSlice from "./reducers/PayrollCatSlice";
import PayrollItemSlice from "./reducers/PayrollItemSlice";
import StaffPayItemSlice from "./reducers/StaffPayItemSlice";
import StaffPayEntrySlice from "./reducers/StaffPayEntrySlice";
import NotificationSlice from "./reducers/NotificationSlice";
import OnlinePaySlice from "./reducers/OnlinePaySlice";
import MpesaPaymentsSlice from "./reducers/MpesaPaymentsSlice";
import MessageSlice from "./reducers/MessageSlice";

const persistedState = LoadState();

const store = configureStore({
	reducer: {
		auth: AuthSlice,
		inst: InstSlice,
		cred: CredSlice,
		mode: ModeSlice,
		serviceCat: ServiceCatSlice,
		service: ServiceSlice,
		expenseCat: ExpenseCatSlice,
		insComp: InsCompSlice,
		expense: ExpenseSlice,
		ward: WardSlice,
		bed: BedSlice,
		pharmacyCat: PharmacyCatSlice,
		product: ProductSlice,
		entry: EntrySlice,
		health: HealthSlice,
		vital: VitalSlice,
		invoice: InvoiceSlice,
		payment: PaymentSlice,
		mpesaPayment: MpesaPaymentsSlice,
		appointment: AppointmentSlice,
		storeCat: StoreCatSlice,
		item: ItemSlice,
		storeEntry: StoreEntrySlice,
		payrollEntry: PayrollEntrySlice,
		payrollCat: PayrollCatSlice,
		payrollItem: PayrollItemSlice,
		staffPayItem: StaffPayItemSlice,
		staffPayEntry: StaffPayEntrySlice,
		notification: NotificationSlice,
		message: MessageSlice,
		onlinePay: OnlinePaySlice,
		sub: SubSlice,
		online: OnlineSlice,
		pwa: PwaSlice,
		active: ActiveSlice,
	},
	preloadedState: persistedState,
});

store.subscribe(() => {
	SaveState(store.getState());
});

export default store;
