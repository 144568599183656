import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { addLiveBed, updateLiveBed } from "../../../Network/BedApi";

const BedCrud = (props) => {
  //redux dispatch
  const dispatch = useDispatch();

  const User = useSelector((state) => state.auth.user);
  const ActiveCred = useSelector((state) => state.cred.activeCred);
  const [Loading, setLoading] = useState(false);

  //edit bed
  const EditBed = async () => {
    let {
      credLinker,

      trace,

      deleted,
    } = props.bed;

    trace = Date.now();
    credLinker = ActiveCred.linker;
    deleted = props.type === "delete" ? 1 : deleted;

    await updateLiveBed(
      { ...props.bed, trace, credLinker, deleted },
      User.token,
      dispatch
    );
  };

  const SaveBed = async () => {
    let trace = Date.now();

    await addLiveBed(
      {
        ...props.bed,
        instLinker: ActiveCred.linker,
        credLinker: ActiveCred.linker,
        live: 1,
        status: 0,
        trace,
        deleted: 0,
      },
      ActiveCred.instLinker,
      User.token,
      dispatch
    );
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (props.type === "add") {
      await SaveBed();
    } else {
      await EditBed();
    }
    setLoading(false);

    props.setShowModal(false);
  };

  return (
    <Modal
      show={props.ShowModal}
      onHide={() => props.setShowModal(false)}
      backdrop="static"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <p className="text-capitalize text-center">{props.type} Bed</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={HandleSubmit}>
          {props.Wards.length > 0 ? (
            <div className="form-group">
              <label className="mb-2 text-capitalize">Ward:</label>
              <select
                className="rounded form-control"
                value={props.bed.wardLinker}
                onChange={(e) =>
                  props.setBed({
                    ...props.bed,
                    wardLinker: e.target.value,
                  })
                }
                required
                readOnly={props.type === "delete" ? true : false}
              >
                <option value={""}>Select ward</option>
                {props.Wards.map((ward, index) => (
                  <option key={index} value={ward.linker}>
                    {ward.name}
                  </option>
                ))}
              </select>
              <hr />
            </div>
          ) : null}
          <div className="form-group">
            <label className="mb-2 text-capitalize"> Bed Number:</label>
            <input
              className="rounded form-control"
              placeholder={`enter bed number`}
              value={props.bed.bedNo}
              onChange={(e) =>
                props.setBed({
                  ...props.bed,
                  bedNo: e.target.value,
                })
              }
              required
              readOnly={props.type === "delete" ? true : false}
            />
            <hr />
          </div>

          <div className="d-flex justify-content-around mb-2">
            {Loading ? (
              <span className="spinner-border text-primary"></span>
            ) : props.type === "delete" ? (
              <Button variant="danger" type="submit">
                Delete
              </Button>
            ) : (
              <Button variant="primary" type="submit">
                Save
              </Button>
            )}
            <Button
              variant="secondary"
              type="button"
              onClick={() => props.setShowModal(false)}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default BedCrud;
