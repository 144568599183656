import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { addLivePayment, updateLivePayment } from "../../../Network/PaymentApi";

const SettlementCrud = (props) => {
  //redux dispatch
  const dispatch = useDispatch();

  const User = useSelector((state) => state.auth.user);
  const ActiveCred = useSelector((state) => state.cred.activeCred);
  const Modes = useSelector((state) => state.mode.modes).filter(
    (mode) => parseInt(mode.deleted) === 0
  );
  const [Loading, setLoading] = useState(false);

  //edit payment
  const EditPayment = async () => {
    let {
      credLinker,

      trace,

      deleted,
    } = props.payment;

    trace = Date.now();
    credLinker = ActiveCred.linker;
    deleted = props.type === "delete" ? 1 : deleted;

    await updateLivePayment(
      { ...props.payment, trace, credLinker, deleted },
      User.token,
      dispatch
    );
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (props.type === "add") {
      await addLivePayment(
        {
          ...props.payment,
          trace: Date.now(),
          credLinker: ActiveCred.linker,
          deleted: 0,
        },
        ActiveCred.instLinker,
        User.token,
        dispatch
      );
    } else {
      await EditPayment();
    }

    setLoading(false);
    props.setShowModal(false);
  };

  return (
    <Modal
      show={props.ShowModal}
      onHide={() => props.setShowModal(false)}
      backdrop="static"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <p className="text-capitalize text-center">{props.type} Settlement</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={HandleSubmit}>
          <div className="form-group">
            <label className="mb-2 text-capitalize">Payment Amount:</label>
            <input
              className="rounded form-control"
              placeholder={`enter payment amount`}
              value={props.payment.amount}
              onChange={(e) =>
                props.setPayment({
                  ...props.payment,
                  amount: e.target.value,
                })
              }
              type="number"
              required
              readOnly={props.type === "delete" ? true : false}
            />
            <hr />
          </div>

          <div className="form-group">
            <label className="mb-2 text-capitalize">Mode Of Payment:</label>
            <select
              className="rounded form-control"
              value={props.payment.mode}
              onChange={(e) =>
                props.setPayment({
                  ...props.payment,
                  mode: e.target.value,
                })
              }
              required
              readOnly={props.type === "delete" ? true : false}
            >
              <option value={""}>Select Payment Mode</option>
              {Modes.map((mode, index) => (
                <option key={index} value={mode.linker}>
                  {mode.name}
                </option>
              ))}
            </select>
            <hr />
          </div>

          <>
            {" "}
            <div className="form-group">
              <label className="mb-2 text-capitalize">
                Transaction/Receipt No:
              </label>
              <input
                className="rounded form-control"
                placeholder={`enter trasaction/receipt No`}
                value={props.payment.code}
                onChange={(e) =>
                  props.setPayment({
                    ...props.payment,
                    code: e.target.value,
                  })
                }
                type="text"
                required
                readOnly={props.type === "delete" ? true : false}
              />
              <hr />
            </div>{" "}
            <div className="form-group">
              <label className="mb-2 text-capitalize">Payment Details:</label>
              <textarea
                className="rounded form-control"
                placeholder={`enter payment details`}
                value={props.payment.details}
                onChange={(e) =>
                  props.setPayment({
                    ...props.payment,
                    details: e.target.value,
                  })
                }
                required
                readOnly={props.type === "delete" ? true : false}
              />
              <hr />
            </div>
          </>

          <div className="d-flex justify-content-around mb-2">
            {Loading ? (
              <span className="spinner-border text-primary"></span>
            ) : props.type === "delete" ? (
              <Button variant="danger" type="submit">
                Delete
              </Button>
            ) : (
              <Button variant="primary" type="submit">
                Save
              </Button>
            )}
            <Button
              variant="secondary"
              type="button"
              onClick={() => props.setShowModal(false)}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default SettlementCrud;
