import React, { useState, useEffect } from "react";
import AppointmentCrud from "./AppointmentCrud";
import { useDispatch, useSelector } from "react-redux";
import NoAppointment from "./NoAppointment";
import AppointmentDisp from "./AppointmentDisp";
import { FetchAppointments } from "../../Network/AppointmentApi";
import { PlainList } from "flatlist-react";
import GeneratePdf from "../../Utils/GeneratePdf";

const Appointments = () => {
  useEffect(() => {
    FetchAppointments(User.token, ActiveCred.instLinker, dispatch, online);
  }, []);

  const [ShowModal, setShowModal] = useState(false);
  const [Appointment, setAppointment] = useState({ name: "" });
  const [Search, setSearch] = useState("");
  const [Type, setType] = useState("add");
  const [By, setBy] = useState("all");
  const [Day, setDay] = useState("all");
  const [Month, setMonth] = useState("all");
  const [Year, setYear] = useState("all");
  const [BookDay, setBookDay] = useState("all");
  const [BookMonth, setBookMonth] = useState("all");
  const [BookYear, setBookYear] = useState("all");
  const [Status, setStatus] = useState("all");
  const [Service, setService] = useState("all");

  //redux dispatch
  const dispatch = useDispatch();

  const User = useSelector((state) => state.auth.user);
  const online = useSelector((state) => state.online.online);
  const ActiveCred = useSelector((state) => state.cred.activeCred);
  const Appointments = useSelector(
    (state) => state.appointment.appointments
  ).filter((appointment) => parseInt(appointment.deleted) === 0);
  const Creds = useSelector((state) => state.cred.creds);
  const Services = useSelector((state) => state.service.services).filter(
    (service) => parseInt(service.deleted) === 0
  );

  const FindUser = (linker) => {
    let cred = Creds.find((cred) => parseInt(cred.linker) == parseInt(linker));

    return cred || { email: "unknown" };
  };

  const SearchAppointment = Appointments.filter(
    (appointment) =>
      ((appointment.name || "").toLowerCase().search(Search.toLowerCase()) >
        -1 ||
        (appointment.details || "").toLowerCase().search(Search.toLowerCase()) >
          -1 ||
        Search === "") &&
      parseInt(appointment.deleted) === 0 &&
      (appointment.status === Status || Status === "all") &&
      (parseInt(appointment.serviceLinker) === parseInt(Service) ||
        Service === "all") &&
      (parseInt(
        (
          new Date(parseInt(appointment.linker)).toLocaleDateString() || ""
        ).split("/")[2]
      ) === parseInt(Year) ||
        Year === "all") &&
      (parseInt(
        (
          new Date(parseInt(appointment.linker)).toLocaleDateString() || ""
        ).split("/")[0]
      ) === parseInt(Month) ||
        Year === "all" ||
        Month === "all") &&
      (parseInt(
        (
          new Date(parseInt(appointment.linker)).toLocaleDateString() || ""
        ).split("/")[1]
      ) === parseInt(Day) ||
        Year === "all" ||
        Month === "all" ||
        Day == "all") &&
      (parseInt((appointment.date || "").split("-")[0]) ===
        parseInt(BookYear) ||
        BookYear === "all") &&
      (parseInt((appointment.date || "").split("-")[1]) ===
        parseInt(BookMonth) ||
        BookYear === "all" ||
        BookMonth === "all") &&
      (parseInt(((appointment.date || "").split("-")[2] || "").slice(0, 2)) ===
        parseInt(BookDay) ||
        BookYear === "all" ||
        BookMonth === "all" ||
        BookDay === "all") &&
      (parseInt(appointment.credLinker) === parseInt(By) || By === "all")
  ).sort((a, b) => parseInt(b.linker) - parseInt(a.linker));

  return (
    <div>
      <div id="appointments">
        <div className="d-flex justify-content-around mt-2">
          <p className="text-center h6 text-primary text-capitalize">
            {SearchAppointment.length} Appointments
          </p>{" "}
          <button
            className="btn btn-sm btn-outline-secondary"
            onClick={() => {
              setType("add");
              setShowModal(true);
              setAppointment({
                linker: Date.now(),
                trace: Date.now(),
                name: "",
                details: "",
                date: "",
                contact: "",
                email: "",
                serviceLinker: "",
                deleted: "0",
                status: "current",
              });
            }}
          >
            Add Appointment
          </button>
        </div>

        <table className="table table-sm">
          <thead className="thead-dark">
            <tr>
              <th scope="column">
                <input
                  type={"text"}
                  placeholder={`Search Appointment`}
                  value={Search}
                  onChange={(e) => setSearch(e.target.value)}
                  className="rounded form-control"
                />
              </th>

              <th scope="column">
                <select
                  className="form-control rounded"
                  value={Service}
                  onChange={(e) => setService(e.target.value)}
                >
                  <option value={"all"}>All Services</option>
                  {Services.map((service, index) => (
                    <option key={index} value={service.linker}>
                      {service.name}
                    </option>
                  ))}
                </select>
              </th>
              <th scope="column">Date</th>
              <th scope="column">Status</th>
              <th scope="column"></th>

              <th scope="column">Booked On</th>
              <th scope="column">By</th>
            </tr>
            <tr>
              <th scope="column">Name</th>
              <th>Service</th>
              <th>
                {" "}
                <div className="d-flex">
                  <select
                    className="form-control rounded"
                    value={BookYear}
                    onChange={(e) => setBookYear(e.target.value)}
                  >
                    <option value={"all"}>All Yrs</option>
                    {[
                      ...new Set(
                        Appointments.map(
                          (appointment) =>
                            (appointment.date || "").split("-")[0]
                        )
                      ),
                    ].map((yr) => (
                      <option key={yr} value={yr}>
                        {yr}
                      </option>
                    ))}
                  </select>
                  {BookYear !== "all" ? (
                    <select
                      className="form-control rounded"
                      value={BookMonth}
                      onChange={(e) => setBookMonth(e.target.value)}
                    >
                      <option value={"all"}>All Mths</option>
                      {[
                        ...new Set(
                          Appointments.map(
                            (appointment) =>
                              (appointment.date || "").split("-")[1]
                          )
                        ),
                      ].map((mth) => (
                        <option key={mth} value={mth}>
                          {mth}
                        </option>
                      ))}
                    </select>
                  ) : null}
                  {BookMonth !== "all" && BookYear !== "all" ? (
                    <select
                      className="form-control rounded"
                      value={BookDay}
                      onChange={(e) => setBookDay(e.target.value)}
                    >
                      <option value={"all"}>All Dates</option>
                      {[
                        ...new Set(
                          Appointments.map((appointment) =>
                            (
                              (appointment.date || "").split("-")[2] || ""
                            ).slice(0, 2)
                          )
                        ),
                      ].map((day) => (
                        <option key={day} value={day}>
                          {day}
                        </option>
                      ))}
                    </select>
                  ) : null}
                </div>
              </th>
              <th scope="column">
                <select
                  className="form-control rounded"
                  value={Status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value={"all"}>All Status</option>
                  <option value={"current"}>Current</option>
                  <option value={"attended"}>Attended</option>
                  <option value={"skipped"}>Skipped</option>
                  <option value={"cancelled"}>Cancelled</option>
                </select>
              </th>
              <th scope="column">Details</th>

              <th>
                <div className="d-flex">
                  <select
                    className="form-control rounded"
                    value={Year}
                    onChange={(e) => setYear(e.target.value)}
                  >
                    <option value={"all"}>All Yrs</option>
                    {[
                      ...new Set(
                        Appointments.map(
                          (appointment) =>
                            (
                              new Date(
                                parseInt(appointment.linker)
                              ).toLocaleDateString() || ""
                            ).split("/")[2]
                        )
                      ),
                    ].map((yr) => (
                      <option key={yr} value={yr}>
                        {yr}
                      </option>
                    ))}
                  </select>
                  {Year !== "all" ? (
                    <select
                      className="form-control rounded"
                      value={Month}
                      onChange={(e) => setMonth(e.target.value)}
                    >
                      <option value={"all"}>All Mths</option>
                      {[
                        ...new Set(
                          Appointments.map(
                            (appointment) =>
                              (
                                new Date(
                                  parseInt(appointment.linker)
                                ).toLocaleDateString() || ""
                              ).split("/")[0]
                          )
                        ),
                      ].map((mth) => (
                        <option key={mth} value={mth}>
                          {mth}
                        </option>
                      ))}
                    </select>
                  ) : null}
                  {Month !== "all" && Year !== "all" ? (
                    <select
                      className="form-control rounded"
                      value={Day}
                      onChange={(e) => setDay(e.target.value)}
                    >
                      <option value={"all"}>All Dates</option>
                      {[
                        ...new Set(
                          Appointments.map(
                            (appointment) =>
                              (
                                new Date(
                                  parseInt(appointment.linker)
                                ).toLocaleDateString() || ""
                              ).split("/")[1]
                          )
                        ),
                      ].map((day) => (
                        <option key={day} value={day}>
                          {day}
                        </option>
                      ))}
                    </select>
                  ) : null}
                </div>
              </th>
              <th>
                <select
                  className="form-control rounded"
                  value={By}
                  onChange={(e) => setBy(e.target.value)}
                >
                  <option value={"all"}>All Staffs</option>
                  <PlainList
                    list={[
                      ...new Set(
                        Appointments.map(
                          (appointment) => appointment.credLinker
                        )
                      ),
                    ]}
                    renderOnScroll
                    renderItem={(staff) => (
                      <option value={staff} key={staff}>
                        {FindUser(staff).email}
                      </option>
                    )}
                  />
                </select>
              </th>
            </tr>
          </thead>
          {SearchAppointment.length > 0 ? (
            <tbody>
              <PlainList
                list={SearchAppointment}
                renderOnScroll
                renderItem={(appointment) => (
                  <AppointmentDisp
                    setShowModal={setShowModal}
                    ShowModal={ShowModal}
                    appointment={appointment}
                    setAppointment={setAppointment}
                    type={Type}
                    setType={setType}
                    key={appointment.linker}
                    Services={Services}
                  ></AppointmentDisp>
                )}
              />
            </tbody>
          ) : (
            <NoAppointment></NoAppointment>
          )}
        </table>
      </div>
      <GeneratePdf id={"appointments"} name={"appointments"}></GeneratePdf>
      <AppointmentCrud
        setShowModal={setShowModal}
        ShowModal={ShowModal}
        appointment={Appointment}
        setAppointment={setAppointment}
        type={Type}
        Services={Services}
      ></AppointmentCrud>
    </div>
  );
};

export default Appointments;
