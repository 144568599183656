import { createSlice } from "@reduxjs/toolkit";

export const ServiceSlice = createSlice({
  name: "service",
  initialState: {
    services: [],
  },
  reducers: {
    fetchServices: (state, action) => {
      state.services = action.payload;
    },
    addService: (state, action) => {
      state.services = [action.payload, ...state.services];
    },
    updateService: (state, action) => {
      let index = state.services.findIndex(
        (service) =>
          parseInt(service.linker) === parseInt(action.payload.linker)
      );

      let prevServices = [...state.services];

      prevServices.splice(
        index < 0 ? 1 : index,
        index < 0 ? 0 : 1,
        action.payload
      );

      state.services = prevServices;
    },
    deleteService: (state, action) => {
      let newServices = state.services.filter(
        (service) =>
          parseInt(service.linker) !== parseInt(action.payload.linker)
      );
      state.services = newServices;
    },
  },
});

export const { fetchServices, addService, updateService, deleteService } =
  ServiceSlice.actions;

export default ServiceSlice.reducer;
