import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import LetterHead from "../../../components/LetterHead";
const ReceiptModal = ({
	Amount,
	Products,
	PrintReceipt,
	setPrintReceipt,
	ResetInputs,
}) => {
	const inst = useSelector((state) => state.inst.activeInst);

	const PrintDiv = (divId) => {
		let divToPrint = document.getElementById(divId);
		let originalContents = document.body.innerHTML;
		document.body.innerHTML = divToPrint.outerHTML;
		window.print();
		document.body.innerHTML = originalContents;
		window.location.reload();
		//setPrintReceipt(false);
	};
	return (
		<Modal
			show={PrintReceipt}
			onHide={() => setPrintReceipt(false)}
			backdrop="static"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>
					<p className="text-capitalize text-center">Print Receipt</p>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div
					className="card"
					style={{ fontFamily: "Monaco", fontSize: "10px" }}
					id="printable"
				>
					<LetterHead></LetterHead>
					<table className="table table-sm table-borderless">
						<thead>
							<tr>
								<th>Prd</th>
								<th>QTY</th>
								<th>Price</th>
								<th>Total</th>
							</tr>
						</thead>
						<tbody>
							{Products.map((product) => (
								<tr>
									<td>{product.product.name}</td>
									<td>{product.qty}</td>
									<td>{product.product.amount}</td>
									<td>{product.qty * product.product.amount}</td>
								</tr>
							))}
							<tr>
								<th colSpan={2}>Sum</th>
								<th colSpan={2}>
									{Products.reduce(
										(a, b) => +a + (b.product.amount || 0) * b.qty,
										0
									)}
								</th>
							</tr>
						</tbody>
					</table>
					<div className="d-flex justify-content-between">
						{" "}
						<p>
							<small>
								<em>
									<strong>Paid :</strong>
									{Amount}
								</em>
							</small>
						</p>{" "}
						<p>
							<small>
								<em>
									<strong>Bal :</strong>
									{Amount -
										Products.reduce(
											(a, b) => +a + (b.product.amount || 0) * b.qty,
											0
										)}
								</em>
							</small>
						</p>{" "}
						<p>
							<small>
								<em>
									<strong>
										{new Date(Date.now()).toLocaleDateString("en-US")}
									</strong>
								</em>
							</small>
						</p>
					</div>
				</div>
				<div className="d-flex justify-content-around mb-2">
					<Button
						variant="danger"
						type="button"
						onClick={() => {
							setPrintReceipt(false);
							ResetInputs();
						}}
					>
						Cancel
					</Button>
					<Button
						variant="secondary"
						type="button"
						onClick={() => PrintDiv("printable")}
					>
						Print
					</Button>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default ReceiptModal;
