import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { updateLiveInvoice } from "../../../Network/InvoiceApi";

const InvoiceCrud = (props) => {
  //redux dispatch
  const dispatch = useDispatch();

  const ActiveCred = useSelector((state) => state.cred.activeCred);
  const User = useSelector((state) => state.auth.user);
  const [Loading, setLoading] = useState(false);

  //edit invoice
  const EditInvoice = async () => {
    let { credLinker, trace, deleted } = props.invoice;

    trace = Date.now();
    credLinker = ActiveCred.linker;
    deleted = props.type === "delete" ? 1 : deleted;

    await updateLiveInvoice(
      { ...props.invoice, trace, credLinker, deleted },
      User.token,
      dispatch
    );
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    await EditInvoice();
    setLoading(false);
    props.setShowModal(false);
  };

  return (
    <Modal
      show={props.ShowModal}
      onHide={() => props.setShowModal(false)}
      backdrop="static"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <p className="text-capitalize text-center">{props.type} Invoice</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={HandleSubmit}>
          <div className="form-group">
            <label className="mb-2 text-capitalize"> Invoice:</label>
            <input
              className="rounded form-control"
              placeholder={`enter invoice`}
              value={props.invoice.name}
              onChange={(e) =>
                props.setInvoice({
                  ...props.invoice,
                  name: e.target.value,
                })
              }
              required
              readOnly={props.type === "delete" ? true : false}
            />
            <hr />
          </div>
          <div className="form-group">
            <label className="mb-2 text-capitalize">Invoice Details:</label>
            <textarea
              className="rounded form-control"
              placeholder={`enter invoice details`}
              value={props.invoice.details}
              onChange={(e) =>
                props.setInvoice({
                  ...props.invoice,
                  details: e.target.value,
                })
              }
              required
              readOnly={props.type === "delete" ? true : false}
            />
            <hr />
          </div>
          <div className="form-group">
            <label className="mb-2 text-capitalize">Invoice Amount:</label>
            <input
              className="rounded form-control"
              placeholder={`enter invoice amount`}
              value={props.invoice.amount}
              onChange={(e) =>
                props.setInvoice({
                  ...props.invoice,
                  amount: e.target.value,
                })
              }
              type="number"
              required
              readOnly={props.type === "delete" ? true : false}
            />
            <hr />
          </div>
          <div className="d-flex justify-content-around mb-2">
            {Loading ? (
              <span className="spinner-border text-primary"></span>
            ) : props.type === "delete" ? (
              <Button variant="danger" type="submit">
                Delete
              </Button>
            ) : (
              <Button variant="primary" type="submit">
                Save
              </Button>
            )}
            <Button
              variant="secondary"
              type="button"
              onClick={() => props.setShowModal(false)}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default InvoiceCrud;
