import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
	Pencil,
	PersonCircle,
	Power,
	Bank,
	BookmarkCheck,
} from "react-bootstrap-icons";
import EditProfile from "./EditProfile";
import { useNavigate } from "react-router-dom";
import { logout } from "../../reducers/AuthSlice";
import { setActiveCred } from "../../reducers/CredSlice";
import { setActiveInst } from "../../reducers/InstSlice";

const Profile = (props) => {
	//redux dispatch
	const dispatch = useDispatch();
	const online = useSelector((state) => state.online.online);
	const User = useSelector((state) => state.auth.user);
	const inst = useSelector((state) => state.inst.activeInst);
	const ActiveCred = useSelector((state) => state.cred.activeCred);
	const [ShowModal, setShowModal] = useState(false);
	const navigate = useNavigate();
	return (
		<>
			<div>
				<p className="text-center">
					<PersonCircle className="text-primary h2 mt-2" />
					<br />
					<span>{User.name || "Add Profile Name"}</span>{" "}
					<Pencil
						className="btn-link text-primary"
						style={{ cursor: "pointer" }}
						onClick={() => setShowModal(true)}
					/>
					<br />
					<span>{User.logger}</span>
				</p>
			</div>

			<div className="row d-flex justify-content-center py-3">
				{" "}
				<div className="col-12 col-md-4 d-flex justify-content-around">
					{" "}
					{!props.from ? (
						<button
							onClick={() => navigate(`/inst`)}
							className="btn btn-sm btn-outline-info"
						>
							<Bank /> My Facilities
						</button>
					) : null}
					{!props.from &&
					(parseInt(ActiveCred.admin) === 1 ||
						parseInt(ActiveCred.medical) === 1 ||
						parseInt(ActiveCred.finance) === 1) ? (
						<button
							onClick={() => navigate(`/${inst.name}/payslips`)}
							className="btn btn-sm btn-outline-primary"
						>
							<BookmarkCheck /> My PaySlips
						</button>
					) : null}
					{User.token ? (
						<button
							className="btn btn-sm btn-outline-danger"
							onClick={() => {
								dispatch(logout());
								dispatch(setActiveCred({}));
								dispatch(setActiveInst({}));
								navigate("/");
							}}
						>
							<Power /> Logout
						</button>
					) : null}
				</div>
			</div>

			<EditProfile
				ShowModal={ShowModal}
				setShowModal={setShowModal}
			></EditProfile>
		</>
	);
};

export default Profile;
