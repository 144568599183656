import { createSlice } from "@reduxjs/toolkit";

export const ServiceCatSlice = createSlice({
  name: "serviceCat",
  initialState: {
    serviceCats: [],
  },
  reducers: {
    fetchServiceCats: (state, action) => {
      state.serviceCats = action.payload;
    },
    addServiceCat: (state, action) => {
      state.serviceCats = [action.payload, ...state.serviceCats];
    },
    updateServiceCat: (state, action) => {
      let index = state.serviceCats.findIndex(
        (serviceCat) =>
          parseInt(serviceCat.linker) === parseInt(action.payload.linker)
      );

      let prevServiceCats = [...state.serviceCats];

      prevServiceCats.splice(
        index < 0 ? 1 : index,
        index < 0 ? 0 : 1,
        action.payload
      );

      state.serviceCats = prevServiceCats;
    },
    deleteServiceCat: (state, action) => {
      let newServiceCats = state.serviceCats.filter(
        (serviceCat) =>
          parseInt(serviceCat.linker) !== parseInt(action.payload.linker)
      );
      state.serviceCats = newServiceCats;
    },
  },
});

export const {
  fetchServiceCats,
  addServiceCat,
  updateServiceCat,
  deleteServiceCat,
} = ServiceCatSlice.actions;

export default ServiceCatSlice.reducer;
