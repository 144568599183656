import { createSlice } from "@reduxjs/toolkit";

export const PharmacyCatSlice = createSlice({
  name: "pharmacyCat",
  initialState: {
    pharmacyCats: [],
  },
  reducers: {
    fetchPharmacyCats: (state, action) => {
      state.pharmacyCats = action.payload;
    },
    addPharmacyCat: (state, action) => {
      state.pharmacyCats = [action.payload, ...state.pharmacyCats];
    },
    updatePharmacyCat: (state, action) => {
      let index = state.pharmacyCats.findIndex(
        (pharmacyCat) =>
          parseInt(pharmacyCat.linker) === parseInt(action.payload.linker)
      );

      let prevPharmacyCats = [...state.pharmacyCats];

      prevPharmacyCats.splice(
        index < 0 ? 1 : index,
        index < 0 ? 0 : 1,
        action.payload
      );

      state.pharmacyCats = prevPharmacyCats;
    },
    deletePharmacyCat: (state, action) => {
      let newPharmacyCats = state.pharmacyCats.filter(
        (pharmacyCat) =>
          parseInt(pharmacyCat.linker) !== parseInt(action.payload.linker)
      );
      state.pharmacyCats = newPharmacyCats;
    },
  },
});

export const {
  fetchPharmacyCats,
  addPharmacyCat,
  updatePharmacyCat,
  deletePharmacyCat,
} = PharmacyCatSlice.actions;

export default PharmacyCatSlice.reducer;
