import React, { useState, useEffect } from "react";
import PharmacyCatCrud from "./PharmacyCatCrud";
import { useDispatch, useSelector } from "react-redux";
import { PlusCircle, Trash, PencilSquare } from "react-bootstrap-icons";
import NoPharmacyCat from "./NoPharmacyCat";
import { FetchPharmacyCats } from "../../../Network/PharmacyCatApi";
import GeneratePdf from "../../../Utils/GeneratePdf";

const PharmacyCats = () => {
  useEffect(() => {
    FetchPharmacyCats(User.token, ActiveCred.instLinker, dispatch, online);
  }, []);

  const [ShowModal, setShowModal] = useState(false);
  const [PharmacyCat, setPharmacyCat] = useState({ name: "" });
  const [Search, setSearch] = useState("");
  const [Type, setType] = useState("add");

  //redux dispatch
  const dispatch = useDispatch();

  const User = useSelector((state) => state.auth.user);
  const online = useSelector((state) => state.online.online);
  const ActiveCred = useSelector((state) => state.cred.activeCred);
  const PharmacyCats = useSelector((state) => state.pharmacyCat.pharmacyCats);

  const SearchPharmacyCat = PharmacyCats.filter(
    (pharmacyCat) =>
      (pharmacyCat.name.toLowerCase().search(Search.toLowerCase()) > -1 ||
        Search === "") &&
      parseInt(pharmacyCat.deleted) === 0
  ).sort((a, b) => parseInt(b.linker) - parseInt(a.linker));

  return (
    <div>
      <p className="text-center h6 text-primary text-capitalize">
        {SearchPharmacyCat.length} Pharmacy Categories
      </p>
      <table className="table table-sm" id="pham-cat">
        <thead className="thead-dark">
          <tr>
            <th scope="column" colSpan={2}>
              <input
                type={"text"}
                placeholder={`Search Pharmacy Categories`}
                value={Search}
                onChange={(e) => setSearch(e.target.value)}
                className="rounded form-control"
              />
            </th>
            <td>
              <button
                className="btn btn-sm btn-primary"
                onClick={() => {
                  setShowModal(true);
                  setType("add");
                  setPharmacyCat({ name: "", details: "" });
                }}
              >
                <PlusCircle /> Add Pharmacy Category
              </button>
            </td>
          </tr>
        </thead>
        {SearchPharmacyCat.length > 0 ? (
          <tbody>
            {SearchPharmacyCat.map((pharmacyCat, index) => (
              <tr key={index} className="item">
                <td>{pharmacyCat.name}</td>
                <td>{pharmacyCat.details}</td>
                <td>
                  <button
                    className="btn btn-xs btn-outline-info mx-1"
                    onClick={() => {
                      setShowModal(true);
                      setType("edit");
                      setPharmacyCat({ ...pharmacyCat });
                    }}
                  >
                    {" "}
                    <PencilSquare style={{ cursor: "pointer" }} />
                  </button>
                  <button
                    className="btn btn-xs btn-outline-danger mx-1"
                    onClick={() => {
                      setShowModal(true);
                      setType("delete");
                      setPharmacyCat({ ...pharmacyCat });
                    }}
                  >
                    {" "}
                    <Trash style={{ cursor: "pointer" }} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        ) : (
          <NoPharmacyCat></NoPharmacyCat>
        )}
      </table>
      <GeneratePdf id="pham-cat" name={"pharmacy-categories"}></GeneratePdf>
      <PharmacyCatCrud
        setShowModal={setShowModal}
        ShowModal={ShowModal}
        pharmacyCat={PharmacyCat}
        setPharmacyCat={setPharmacyCat}
        type={Type}
      ></PharmacyCatCrud>
    </div>
  );
};

export default PharmacyCats;
