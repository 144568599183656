import React, { useState } from "react";
import { Trash, PencilSquare } from "react-bootstrap-icons";
import { useSelector } from "react-redux";

const HealthDisp = ({ health, setShowModal, setHealth, setType }) => {
  const [More, setMore] = useState(false);

  const ActiveCred = useSelector((state) => state.cred.activeCred);

  const TrimText = (text, length) => {
    if (text.length <= length) {
      return text;
    }
    return text.substr(0, length);
  };
  return (
    <div className="row d-flex justify-content-center my-2">
      <div className="col-10 col-md-8">
        <div className="card card-dark bg-transparent">
          <div className="card-body bg-transparent">
            {More ? (
              <p style={{ whiteSpace: "pre-line" }}>{health.note}</p>
            ) : (
              <p style={{ whiteSpace: "pre-line" }}>
                {TrimText(health.note, 100)}{" "}
                {health.note.length > 100 ? (
                  <span
                    className="btn-link text-primary"
                    style={{ cursor: "pointer" }}
                    onClick={() => setMore(true)}
                  >
                    ...more
                  </span>
                ) : (
                  ""
                )}
              </p>
            )}
          </div>
          <div className="card-footer">
            <div className="d-flex justify-content-between">
              <em>
                <small>
                  {new Date(parseInt(health.linker)).toLocaleDateString(
                    "en-US"
                  )}
                </small>
              </em>
              {parseInt(ActiveCred.admin) === 1 ||
              parseInt(ActiveCred.medical) === 1 ? (
                <div>
                  <PencilSquare
                    className="text-info mx-1"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setHealth(health);
                      setType("edit");
                      setShowModal(true);
                    }}
                  ></PencilSquare>

                  <Trash
                    className="text-danger mx-1"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setHealth(health);
                      setType("delete");
                      setShowModal(true);
                    }}
                  ></Trash>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HealthDisp;
