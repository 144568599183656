import { createSlice } from "@reduxjs/toolkit";

export const WardSlice = createSlice({
  name: "ward",
  initialState: {
    wards: [],
  },
  reducers: {
    fetchWards: (state, action) => {
      state.wards = action.payload;
    },
    addWard: (state, action) => {
      state.wards = [action.payload, ...state.wards];
    },
    updateWard: (state, action) => {
      let index = state.wards.findIndex(
        (ward) => parseInt(ward.linker) === parseInt(action.payload.linker)
      );

      let prevWards = [...state.wards];

      prevWards.splice(
        index < 0 ? 1 : index,
        index < 0 ? 0 : 1,
        action.payload
      );

      state.wards = prevWards;
    },
    deleteWard: (state, action) => {
      let newWards = state.wards.filter(
        (ward) => parseInt(ward.linker) !== parseInt(action.payload.linker)
      );
      state.wards = newWards;
    },
  },
});

export const { fetchWards, addWard, updateWard, deleteWard } =
  WardSlice.actions;

export default WardSlice.reducer;
