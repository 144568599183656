import React, { useState, useEffect } from "react";
import InvoiceCrud from "./InvoiceCrud";
import { useDispatch, useSelector } from "react-redux";
import NoInvoice from "./NoInvoice";
import { fetchCreds } from "../../../reducers/CredSlice";
import InvoiceDisp from "./InvoiceDisp";
import { FetchInvoices } from "../../../Network/InvoiceApi";
import { PlainList } from "flatlist-react";
import GeneratePdf from "../../../Utils/GeneratePdf";

const Invoices = () => {
  useEffect(() => {
    FetchInvoices(User.token, ActiveCred.instLinker, dispatch, online);
  }, []);

  const [ShowModal, setShowModal] = useState(false);
  const [Invoice, setInvoice] = useState({ name: "" });
  const [Search, setSearch] = useState("");
  const [Type, setType] = useState("add");
  const [By, setBy] = useState("all");
  const [Day, setDay] = useState("all");
  const [Month, setMonth] = useState("all");
  const [Year, setYear] = useState("all");

  //redux dispatch
  const dispatch = useDispatch();

  const User = useSelector((state) => state.auth.user);
  const online = useSelector((state) => state.online.online);
  const ActiveCred = useSelector((state) => state.cred.activeCred);
  const Invoices = useSelector((state) => state.invoice.invoices);
  const Creds = useSelector((state) => state.cred.creds);

  const FindUser = (linker) => {
    let cred = Creds.find((cred) => parseInt(cred.linker) == parseInt(linker));

    return cred || { email: "unknown" };
  };

  const SearchInvoice = Invoices.filter(
    (invoice) =>
      (invoice.name.toLowerCase().search(Search.toLowerCase()) > -1 ||
        invoice.details.toLowerCase().search(Search.toLowerCase()) > -1 ||
        Search === "") &&
      parseInt(invoice.deleted) === 0 &&
      (parseInt(
        new Date(parseInt(invoice.linker)).toLocaleDateString().split("/")[2]
      ) === parseInt(Year) ||
        Year === "all") &&
      (parseInt(
        new Date(parseInt(invoice.linker)).toLocaleDateString().split("/")[0]
      ) === parseInt(Month) ||
        Year === "all" ||
        Month === "all") &&
      (parseInt(
        new Date(parseInt(invoice.linker)).toLocaleDateString().split("/")[1]
      ) === parseInt(Day) ||
        Year === "all" ||
        Month === "all" ||
        Day == "all") &&
      (parseInt(invoice.credLinker) === parseInt(By) || By === "all")
  ).sort((a, b) => parseInt(b.linker) - parseInt(a.linker));

  return (
    <div>
      <div id="invoices">
        <p className="text-center h6 text-primary text-capitalize">
          {SearchInvoice.length} Invoices
        </p>
        <table className="table table-sm">
          <thead className="thead-dark">
            <tr>
              <th scope="column" colSpan={2}>
                <input
                  type={"text"}
                  placeholder={`Search Invoice`}
                  value={Search}
                  onChange={(e) => setSearch(e.target.value)}
                  className="rounded form-control"
                />
              </th>
              <th scope="column">Amount</th>
              <th scope="column">Patient</th>
              <th scope="column">Date</th>
              <th scope="column">By</th>
            </tr>
            <tr>
              <th scope="column">Invoice</th>
              <th scope="column">Details</th>
              <th>{SearchInvoice.reduce((a, b) => +a + +b.amount, 0)}</th>
              <th colSpan={2}>
                <div className="d-flex">
                  <select
                    className="form-control rounded"
                    value={Year}
                    onChange={(e) => setYear(e.target.value)}
                  >
                    <option value={"all"}>All Yrs</option>
                    {[
                      ...new Set(
                        Invoices.map(
                          (invoice) =>
                            new Date(parseInt(invoice.linker))
                              .toLocaleDateString()
                              .split("/")[2]
                        )
                      ),
                    ].map((yr) => (
                      <option key={yr} value={yr}>
                        {yr}
                      </option>
                    ))}
                  </select>
                  {Year !== "all" ? (
                    <select
                      value={Month}
                      onChange={(e) => setMonth(e.target.value)}
                      className="form-control rounded"
                    >
                      <option value={"all"}>All Mths</option>
                      {[
                        ...new Set(
                          Invoices.map(
                            (invoice) =>
                              new Date(parseInt(invoice.linker))
                                .toLocaleDateString()
                                .split("/")[0]
                          )
                        ),
                      ].map((mth) => (
                        <option key={mth} value={mth}>
                          {mth}
                        </option>
                      ))}
                    </select>
                  ) : null}
                  {Month !== "all" && Year !== "all" ? (
                    <select
                      className="form-control rounded"
                      value={Day}
                      onChange={(e) => setDay(e.target.value)}
                    >
                      <option value={"all"}>All Dates</option>
                      {[
                        ...new Set(
                          Invoices.map(
                            (invoice) =>
                              new Date(parseInt(invoice.linker))
                                .toLocaleDateString()
                                .split("/")[1]
                          )
                        ),
                      ].map((day) => (
                        <option key={day} value={day}>
                          {day}
                        </option>
                      ))}
                    </select>
                  ) : null}
                </div>
              </th>
              <th>
                <select
                  className="form-control rounded"
                  value={By}
                  onChange={(e) => setBy(e.target.value)}
                >
                  <option value={"all"}>All Staffs</option>
                  <PlainList
                    list={[
                      ...new Set(Invoices.map((invoice) => invoice.credLinker)),
                    ]}
                    renderOnScroll
                    renderItem={(staff) => (
                      <option value={staff} key={staff}>
                        {FindUser(staff).email}
                      </option>
                    )}
                  />
                </select>
              </th>
            </tr>
          </thead>
          {SearchInvoice.length > 0 ? (
            <tbody>
              <PlainList
                list={SearchInvoice}
                renderOnScroll
                renderItem={(invoice) => (
                  <InvoiceDisp
                    setShowModal={setShowModal}
                    ShowModal={ShowModal}
                    invoice={invoice}
                    setInvoice={setInvoice}
                    type={Type}
                    setType={setType}
                    key={invoice.linker}
                  ></InvoiceDisp>
                )}
              />
            </tbody>
          ) : (
            <NoInvoice></NoInvoice>
          )}
        </table>
      </div>
      <GeneratePdf id={"invoices"} name={"invoices"}></GeneratePdf>
      <InvoiceCrud
        setShowModal={setShowModal}
        ShowModal={ShowModal}
        invoice={Invoice}
        setInvoice={setInvoice}
        type={Type}
      ></InvoiceCrud>
    </div>
  );
};

export default Invoices;
