import React from "react";
import { useSelector } from "react-redux";
import { Trash, PencilSquare } from "react-bootstrap-icons";
import { Dropdown } from "react-bootstrap";

const AppointmentDisp = ({
  appointment,
  setShowModal,
  setType,
  setAppointment,
  Services,
}) => {
  const Creds = useSelector((state) => state.cred.creds);

  const FindUser = (linker) => {
    let cred = Creds.find((cred) => parseInt(cred.linker) == parseInt(linker));

    return cred || { firstname: "unknown" };
  };

  const FindService = (linker) => {
    let Service = Services.find(
      (service) => parseInt(service.linker) === parseInt(linker)
    );
    return Service || { name: "unknown" };
  };

  return (
    <tr className="item">
      <td>
        <Dropdown variant="transparent">
          <Dropdown.Toggle variant="transparent">
            {appointment.name}
          </Dropdown.Toggle>
          <Dropdown.Menu variant="transparent">
            <Dropdown.Item>{appointment.contact}</Dropdown.Item>
            <Dropdown.Item>{appointment.email}</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </td>
      <td>{FindService(appointment.serviceLinker).name}</td>
      <td>{appointment.date}</td>
      <td>{appointment.status}</td>
      <td>{appointment.details}</td>

      <td>
        {new Date(parseInt(appointment.linker)).toLocaleDateString("en-US")}
      </td>
      <td>{FindUser(appointment.credLinker).email}</td>
      <td>
        <PencilSquare
          onClick={() => {
            setShowModal(true);
            setType("edit");
            setAppointment({ ...appointment });
          }}
          style={{ cursor: "pointer" }}
          className="text-info mx-2"
        />

        <Trash
          onClick={() => {
            setShowModal(true);
            setType("delete");
            setAppointment({ ...appointment });
          }}
          style={{ cursor: "pointer" }}
          className="text-danger mx-2"
        />
      </td>
    </tr>
  );
};

export default AppointmentDisp;
