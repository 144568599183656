import React from "react";
import { Trash, PencilSquare } from "react-bootstrap-icons";

const BedDisp = ({ bed, setShowModal, setType, setBed, Wards }) => {
  const FindWard = (linker) => {
    let Ward = Wards.find((ward) => parseInt(ward.linker) === parseInt(linker));
    return Ward || { name: "unknown" };
  };

  return (
    <tr className="item">
      <td>{bed.bedNo}</td>
      <td>{FindWard(bed.wardLinker).name}</td>
      <td>
        <PencilSquare
          onClick={() => {
            setShowModal(true);
            setType("edit");
            setBed({ ...bed });
          }}
          style={{ cursor: "pointer" }}
          className="text-info mx-2"
        />

        <Trash
          onClick={() => {
            setShowModal(true);
            setType("delete");
            setBed({ ...bed });
          }}
          style={{ cursor: "pointer" }}
          className="text-danger mx-2"
        />
      </td>
    </tr>
  );
};

export default BedDisp;
