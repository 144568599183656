import { createSlice } from "@reduxjs/toolkit";

export const InsCompSlice = createSlice({
  name: "insComp",
  initialState: {
    insComps: [],
  },
  reducers: {
    fetchInsComps: (state, action) => {
      state.insComps = action.payload;
    },
    addInsComp: (state, action) => {
      state.insComps = [action.payload, ...state.insComps];
    },
    updateInsComp: (state, action) => {
      let index = state.insComps.findIndex(
        (insComp) =>
          parseInt(insComp.linker) === parseInt(action.payload.linker)
      );

      let prevInsComps = [...state.insComps];

      prevInsComps.splice(
        index < 0 ? 1 : index,
        index < 0 ? 0 : 1,
        action.payload
      );

      state.insComps = prevInsComps;
    },
    deleteInsComp: (state, action) => {
      let newInsComps = state.insComps.filter(
        (insComp) =>
          parseInt(insComp.linker) !== parseInt(action.payload.linker)
      );
      state.insComps = newInsComps;
    },
  },
});

export const { fetchInsComps, addInsComp, updateInsComp, deleteInsComp } =
  InsCompSlice.actions;

export default InsCompSlice.reducer;
