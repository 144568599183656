import React, { useState, useEffect } from "react";
import InsCompCrud from "./InsCompCrud";
import { useDispatch, useSelector } from "react-redux";
import { PlusCircle, Trash, PencilSquare } from "react-bootstrap-icons";
import NoInsComp from "./NoInsComp";
import { FetchInsComps } from "../../../Network/InsCompApi";
import GeneratePdf from "../../../Utils/GeneratePdf";

const InsComps = () => {
  useEffect(() => {
    FetchInsComps(User.token, ActiveCred.instLinker, dispatch, online);
  }, []);

  const [ShowModal, setShowModal] = useState(false);
  const [InsComp, setInsComp] = useState({ name: "" });
  const [Search, setSearch] = useState("");
  const [Type, setType] = useState("add");

  //redux dispatch
  const dispatch = useDispatch();

  const User = useSelector((state) => state.auth.user);
  const online = useSelector((state) => state.online.online);
  const ActiveCred = useSelector((state) => state.cred.activeCred);
  const InsComps = useSelector((state) => state.insComp.insComps);

  const SearchInsComp = InsComps.filter(
    (insComp) =>
      (insComp.name.toLowerCase().search(Search.toLowerCase()) > -1 ||
        Search === "") &&
      parseInt(insComp.deleted) === 0
  ).sort((a, b) => parseInt(b.linker) - parseInt(a.linker));

  return (
    <div>
      <p className="text-center h6 text-primary text-capitalize">
        Insurance Companies
      </p>
      <table className="table table-sm" id="ins-comps">
        <thead className="thead-dark">
          <tr>
            <th scope="column" colSpan={2}>
              <input
                type={"text"}
                placeholder={`Search insurances`}
                value={Search}
                onChange={(e) => setSearch(e.target.value)}
                className="rounded form-control"
              />
            </th>
            <td>
              <button
                className="btn btn-sm btn-primary"
                onClick={() => {
                  setShowModal(true);
                  setType("add");
                  setInsComp({ name: "", details: "" });
                }}
              >
                <PlusCircle /> Add Insurance Company
              </button>
            </td>
          </tr>
        </thead>
        {SearchInsComp.length > 0 ? (
          <tbody>
            {SearchInsComp.map((insComp, index) => (
              <tr key={index} className="item">
                <td>{insComp.name}</td>
                <td>{insComp.details}</td>
                <td>
                  <button
                    className="btn btn-xs btn-outline-info mx-1"
                    onClick={() => {
                      setShowModal(true);
                      setType("edit");
                      setInsComp({ ...insComp });
                    }}
                  >
                    {" "}
                    <PencilSquare style={{ cursor: "pointer" }} />
                  </button>
                  <button
                    className="btn btn-xs btn-outline-danger mx-1"
                    onClick={() => {
                      setShowModal(true);
                      setType("delete");
                      setInsComp({ ...insComp });
                    }}
                  >
                    {" "}
                    <Trash style={{ cursor: "pointer" }} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        ) : (
          <NoInsComp></NoInsComp>
        )}
      </table>
      <GeneratePdf id={"ins-comps"} name={"insurance-companies"}></GeneratePdf>
      <InsCompCrud
        setShowModal={setShowModal}
        ShowModal={ShowModal}
        insComp={InsComp}
        setInsComp={setInsComp}
        type={Type}
      ></InsCompCrud>
    </div>
  );
};

export default InsComps;
