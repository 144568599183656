import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { addLiveService, updateLiveService } from "../../../Network/ServiceApi";

const ServiceCrud = (props) => {
  //redux dispatch
  const dispatch = useDispatch();

  const User = useSelector((state) => state.auth.user);
  const ActiveCred = useSelector((state) => state.cred.activeCred);
  const [Loading, setLoading] = useState(false);

  //edit service
  const EditService = async () => {
    let {
      credLinker,

      trace,

      deleted,
    } = props.service;

    trace = Date.now();
    credLinker = ActiveCred.linker;
    deleted = props.type === "delete" ? 1 : deleted;

    await updateLiveService(
      { ...props.service, trace, credLinker, deleted },
      User.token,
      dispatch
    );
  };

  const SaveService = async () => {
    let trace = Date.now();

    await addLiveService(
      {
        ...props.service,
        instLinker: ActiveCred.linker,
        credLinker: ActiveCred.linker,
        live: 1,
        status: 0,
        trace,
        deleted: 0,
      },
      ActiveCred.instLinker,
      User.token,
      dispatch
    );
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (props.type === "add") {
      await SaveService();
    } else {
      await EditService();
    }
    setLoading(false);

    props.setShowModal(false);
  };

  return (
    <Modal
      show={props.ShowModal}
      onHide={() => props.setShowModal(false)}
      backdrop="static"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <p className="text-capitalize text-center">{props.type} Service</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={HandleSubmit}>
          {props.Cats.length > 0 ? (
            <div className="form-group">
              <label className="mb-2 text-capitalize">Service Category:</label>
              <select
                className="rounded form-control"
                value={props.service.catLinker}
                onChange={(e) =>
                  props.setService({
                    ...props.service,
                    catLinker: e.target.value,
                  })
                }
                required
                readOnly={props.type === "delete" ? true : false}
              >
                <option value={""}>Select Service Category</option>
                {props.Cats.map((cat, index) => (
                  <option key={index} value={cat.linker}>
                    {cat.name}
                  </option>
                ))}
              </select>
              <hr />
            </div>
          ) : null}
          <div className="form-group">
            <label className="mb-2 text-capitalize"> Service:</label>
            <input
              className="rounded form-control"
              placeholder={`enter service`}
              value={props.service.name}
              onChange={(e) =>
                props.setService({
                  ...props.service,
                  name: e.target.value,
                })
              }
              required
              readOnly={props.type === "delete" ? true : false}
            />
            <hr />
          </div>
          <div className="form-group">
            <label className="mb-2 text-capitalize">Service Details:</label>
            <textarea
              className="rounded form-control"
              placeholder={`enter service details`}
              value={props.service.details}
              onChange={(e) =>
                props.setService({
                  ...props.service,
                  details: e.target.value,
                })
              }
              required
              readOnly={props.type === "delete" ? true : false}
            />
            <hr />
          </div>
          <div className="form-group">
            <label className="mb-2 text-capitalize">Service Cost:</label>
            <input
              className="rounded form-control"
              placeholder={`enter service amount`}
              value={props.service.amount}
              onChange={(e) =>
                props.setService({
                  ...props.service,
                  amount: e.target.value,
                })
              }
              type="number"
              required
              readOnly={props.type === "delete" ? true : false}
            />
            <hr />
          </div>
          <div className="d-flex justify-content-around mb-2">
            {Loading ? (
              <span className="spinner-border text-primary"></span>
            ) : props.type === "delete" ? (
              <Button variant="danger" type="submit">
                Delete
              </Button>
            ) : (
              <Button variant="primary" type="submit">
                Save
              </Button>
            )}
            <Button
              variant="secondary"
              type="button"
              onClick={() => props.setShowModal(false)}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ServiceCrud;
