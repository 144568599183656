import swal from "sweetalert";
import {
  addStoreCat,
  fetchStoreCats,
  updateStoreCat,
} from "../reducers/StoreCatSlice";
import { ApiUrl } from "./Urls";

export const FetchStoreCats = async (
  token,
  instLinker,
  dispatch,
  online,
  from = "fetch",
  payload = {}
) => {
  if (from === "fetch") {
    FetchLiveStoreCats(token, instLinker, online, dispatch);
  } else if (parseInt(instLinker) === parseInt(payload.instLinker)) {
    dispatch(updateStoreCat(payload));
  }
};

export const addLiveStoreCat = (storeCat, instLinker, token, dispatch) => {
  return fetch(`${ApiUrl}/store-categories/add`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    },
    body: JSON.stringify({
      ...storeCat,
      instLinker,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 201) {
        dispatch(updateStoreCat(res.storeCat));
      } else {
        swal({
          title: "Failed to connect",
          icon: "warning",
          timer: 3000,
        });
      }
    })
    .catch(() => {
      swal({
        title: "Failed to connect",
        icon: "warning",
        timer: 3000,
      });
    });
};

export const updateLiveStoreCat = (storeCat, token, dispatch) => {
  return fetch(`${ApiUrl}/store-categories/edit`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    },
    body: JSON.stringify({
      ...storeCat,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 200) {
        dispatch(updateStoreCat(res.storeCat));
      } else {
        swal({
          title: "Failed to connect",
          icon: "warning",
          timer: 3000,
        });
      }
    })
    .catch(() => {
      swal({
        title: "Failed to connect",
        icon: "warning",
        timer: 3000,
      });
    });
};

export const FetchLiveStoreCats = (token, instLinker, online, dispatch) => {
  fetch(`${ApiUrl}/store-categories/get`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    },
    body: JSON.stringify({
      online,
      instLinker,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 200) {
        dispatch(fetchStoreCats(res.storeCats));
      }
    })
    .catch(() => {});
};
