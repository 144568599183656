import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { addLivePayrollEntry } from "../../../../Network/PayrollEntryApi";

const GeneratePayslip = (props) => {
	//redux dispatch
	const dispatch = useDispatch();

	const ActiveCred = useSelector((state) => state.cred.activeCred);
	const online = useSelector((state) => state.online.online);
	const User = useSelector((state) => state.auth.user);
	const Cats = useSelector((state) => state.payrollCat.payrollCats);

	const GetCat = (catLinker) => {
		let cat = Cats.find((cat) => parseInt(catLinker) === parseInt(cat.linker));

		return cat || { name: catLinker };
	};

	const [Loading, setLoading] = useState(false);
	const [Month, setMonth] = useState("");
	const [InputItems, setInputItems] = useState([]);

	useEffect(() => {
		setInputItems(props.items);
	}, [props.items]);

	//add cat
	const AddItem = () => {
		let linker = Date.now();

		InputItems.map((item, index) =>
			addLivePayrollEntry(
				{
					month: Month,
					staffLinker: item.staffLinker,
					itemLinker: item.linker,
					details: item.details,
					amount: item.amount,
					linker: linker + index,
					instLinker: ActiveCred.linker,
					credLinker: ActiveCred.linker,
					live: 1,
					status: 0,
					trace: linker,
					deleted: 0,
				},
				ActiveCred.instLinker,
				User.token,
				dispatch,
				setLoading,
				InputItems,
				index,
				props.setShowModal
			)
		);
	};

	const HandleSubmit = (e) => {
		e.preventDefault();
		setLoading(true);

		AddItem();
	};

	const UpdateInputItem = (value, index) => {
		let newItems = [...InputItems];
		let newItem = { ...newItems[index], amount: value };
		newItems[index] = newItem;
		setInputItems([...newItems]);
	};

	return (
		<Modal
			show={props.ShowModal}
			onHide={() => props.setShowModal(false)}
			backdrop="static"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>
					<p className="text-capitalize text-center">
						Generate Payslip For <br />
						{props.staffNo} {props.staffName}
					</p>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form onSubmit={HandleSubmit}>
					<div className="form-group">
						<label className="mb-2 text-capitalize">Select Month:</label>
						<input
							className="rounded-pill form-control"
							value={Month}
							placeholder={`enter month`}
							onChange={(e) => setMonth(e.target.value)}
							type="month"
							required
							readOnly={props.type === "delete" ? true : false}
							onClick={(e) => e.target.showPicker()}
						/>

						<hr />
					</div>
					{InputItems.map((item, index) => (
						<div className="form-group" key={index}>
							<label className="mb-2 text-capitalize">
								{GetCat(item.catLinker).name}:
							</label>
							<input
								className="rounded-pill form-control"
								value={item.amount}
								placeholder={`enter amount`}
								onChange={(e) => UpdateInputItem(e.target.value, index)}
								type={"number"}
								required
								readOnly={props.type === "delete" ? true : false}
							/>

							<hr />
						</div>
					))}
					<div className="d-flex justify-content-around mb-2">
						{Loading ? (
							<span className="spinner-border text-primary"></span>
						) : (
							<Button variant="primary" type="submit">
								Save
							</Button>
						)}
						<Button
							variant="secondary"
							type="button"
							onClick={() => props.setShowModal(false)}
						>
							Cancel
						</Button>
					</div>
				</Form>
			</Modal.Body>
		</Modal>
	);
};

export default GeneratePayslip;
