import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { addLiveVital, updateLiveVital } from "../../Network/VitalApi";

const VitalCrud = (props) => {
  //redux dispatch
  const dispatch = useDispatch();

  const ActiveCred = useSelector((state) => state.cred.activeCred);
  const User = useSelector((state) => state.auth.user);
  const [Loading, setLoading] = useState(false);

  //add vital
  const AddVital = async () => {
    let linker = Date.now();

    await addLiveVital(
      {
        patientLinker: props.vital.patientLinker,
        linker,
        note: props.vital.note,
        instLinker: ActiveCred.linker,
        credLinker: ActiveCred.linker,
        live: 1,
        status: 0,
        trace: linker,
        deleted: 0,
      },
      ActiveCred.instLinker,
      User.token,
      dispatch
    );

    props.setVital({
      ...props.vital,
      note: "",
    });
  };

  //edit vital
  const EditVital = async () => {
    let {
      credLinker,

      trace,
      live,
      deleted,
    } = props.vital;

    trace = Date.now();
    credLinker = ActiveCred.linker;
    deleted = props.type === "delete" ? 1 : deleted;

    await updateLiveVital(
      { ...props.vital, trace, credLinker, deleted },
      User.token,
      dispatch
    );
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (props.type === "add") {
      await AddVital();
    } else {
      await EditVital();
    }
    setLoading(false);
    props.setShowModal(false);
  };

  return (
    <Modal
      show={props.ShowModal}
      onHide={() => props.setShowModal(false)}
      backdrop="static"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <p className="text-capitalize text-center">
            {props.type} {props.patient.patientReg} {props.patient.firstname}{" "}
            {props.patient.lastname} {props.patient.surname} Vital Record
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={HandleSubmit}>
          <div className="form-group">
            <label className="mb-2 text-capitalize">Vital Record:</label>
            <textarea
              className="rounded form-control"
              placeholder={`enter vital record`}
              value={props.vital.note}
              onChange={(e) =>
                props.setVital({
                  ...props.vital,
                  note: e.target.value,
                })
              }
              required
              readOnly={props.type === "delete" ? true : false}
            />
            <hr />
          </div>
          <div className="d-flex justify-content-around mb-2">
            {Loading ? (
              <span className="spinner-border text-primary"></span>
            ) : props.type === "delete" ? (
              <Button variant="danger" type="submit">
                Delete
              </Button>
            ) : (
              <Button variant="primary" type="submit">
                Save
              </Button>
            )}
            <Button
              variant="secondary"
              type="button"
              onClick={() => props.setShowModal(false)}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default VitalCrud;
