import React, { useState } from "react";
import { Button } from "react-bootstrap";
import {
  ArrowBarLeft,
  FilePdf,
  Trash,
  PencilSquare,
} from "react-bootstrap-icons";

import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import SettlementCrud from "./SettlementCrud";
import GeneratePdf from "../../../Utils/GeneratePdf";

const InsuranceDetails = () => {
  const params = useParams();
  const Navigate = useNavigate();

  const ActiveCred = useSelector((state) => state.cred.activeCred);
  const Creds = useSelector((state) => state.cred.creds);

  const Insurance = useSelector((state) => state.payment.payments).find(
    (payment) => parseInt(payment.linker) === parseInt(params.insuranceLinker)
  ) || { name: "Unknown" };

  const Modes = useSelector((state) => state.mode.modes).filter(
    (mode) => parseInt(mode.deleted) === 0
  );

  const Ins = useSelector((state) => state.insComp.insComps).filter(
    (ins) => parseInt(ins.deleted) === 0
  );

  const FindUser = (linker) => {
    let cred = Creds.find((cred) => parseInt(cred.linker) == parseInt(linker));

    return cred || { firstname: "unknown" };
  };

  const Settlements = useSelector((state) => state.payment.payments).filter(
    (record) =>
      parseInt(record.deleted) === 0 &&
      parseInt(record.insLinker) === parseInt(params.insuranceLinker)
  );

  const FindMode = (linker) => {
    let mode = [...Modes, ...Ins].find(
      (mode) => parseInt(mode.linker) === parseInt(linker)
    );

    return mode || { name: "unknown" };
  };

  const [Type, setType] = useState("add");
  const [SettleModal, setSettleModal] = useState(false);
  const [Settle, setSettle] = useState({});

  return (
    <>
      <div>
        <div className="d-flex justify-content-around my-2">
          <button
            className="btn btn-sm btn-outline-secondary"
            onClick={() => Navigate(-1)}
          >
            <ArrowBarLeft></ArrowBarLeft> Go Back
          </button>

          <button
            className="btn btn-sm btn-outline-secondary"
            onClick={() => {
              setSettleModal(true);
              setSettle({
                id: 0,
                linker: Date.now(),
                name: FindMode(Insurance.mode).name,
                details: "",
                credLinker: ActiveCred.linker,
                patientLinker: "",
                amount: "",
                code: "",
                mode: "",
                instLinker: ActiveCred.instLinker,
                insLinker: params.insuranceLinker,
                status: "settlement",
                trace: Date.now(),
                live: 0,
                deleted: 0,
                createdAt: "",
                updatedAt: "",
              });
              setType("add");
            }}
            style={{ cursor: "pointer" }}
          >
            Pay
          </button>
        </div>
        <div id="insurance-details">
          <p className="h5 text-center">
            {FindMode(Insurance.mode).name} {Insurance.amount}
          </p>
          <p className="h6 text-center">
            {Insurance.name} {FindUser(Insurance.patientLinker).patientReg}{" "}
            {FindUser(Insurance.patientLinker).firstname}{" "}
            {FindUser(Insurance.patientLinker).lastname}{" "}
            {FindUser(Insurance.patientLinker).surname} Settlements
          </p>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Name</th>
                <th>Mode</th>
                <th>Details</th>
                <th>Amt</th>
                <th>Date</th>
                <th>Staff</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {Settlements.map((record, index) => (
                <tr key={index}>
                  <td>{record.name}</td>
                  <td>{FindMode(record.mode).name}</td>
                  <td>
                    {record.code} {record.details}
                  </td>
                  <td>{record.amount}</td>
                  <td>
                    {new Date(parseInt(record.linker)).toLocaleDateString(
                      "en-US"
                    )}
                  </td>
                  <td>{FindUser(record.credLinker).email}</td>
                  {parseInt(ActiveCred.admin) === 1 ||
                  parseInt(ActiveCred.finance) === 1 ? (
                    <td>
                      <PencilSquare
                        onClick={() => {
                          setSettle({ ...record });
                          setSettleModal(true);
                          setType("edit");
                        }}
                        style={{ cursor: "pointer" }}
                        className="text-info mx-2"
                      />

                      <Trash
                        onClick={() => {
                          setSettle({ ...record });
                          setSettleModal(true);
                          setType("delete");
                        }}
                        style={{ cursor: "pointer" }}
                        className="text-danger mx-2"
                      />
                    </td>
                  ) : null}
                </tr>
              ))}
              <tr>
                <th colSpan={3}>Total</th>

                <th colSpan={4}>
                  {Settlements.reduce((a, b) => +a + +b.amount, 0)}
                </th>
              </tr>
              <tr>
                <th colSpan={3}>Balance</th>
                <th colSpan={4}>
                  {parseInt(Insurance.amount) -
                    Settlements.reduce((a, b) => +a + +b.amount, 0)}
                </th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <GeneratePdf
        id={"insurance-details"}
        name={`${FindMode(Insurance.mode).name}-${Insurance.amount}-${
          Insurance.name
        }-${FindUser(Insurance.patientLinker).patientReg}-${
          FindUser(Insurance.patientLinker).firstname
        }-${FindUser(Insurance.patientLinker).lastname}-${
          FindUser(Insurance.patientLinker).surname
        }-Settlements`}
      ></GeneratePdf>
      <SettlementCrud
        setShowModal={setSettleModal}
        ShowModal={SettleModal}
        payment={Settle}
        setPayment={setSettle}
        type={Type}
      ></SettlementCrud>
    </>
  );
};

export default InsuranceDetails;
