import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NoStoreEntry from "./NoStoreEntry";
import { fetchCreds } from "../../../reducers/CredSlice";
import StoreEntryDisp from "./StoreEntryDisp";
import { FetchStoreEntries } from "../../../Network/StoreEntryApi";
import { PlainList } from "flatlist-react";
import ItemStoreEntry from "../Items/ItemEntry";
import GeneratePdf from "../../../Utils/GeneratePdf";

const StoreEntries = () => {
	useEffect(() => {
		FetchStoreEntries(User.token, ActiveCred.instLinker, dispatch, online);
	}, []);

	const [ShowModal, setShowModal] = useState(false);
	const [StoreEntry, setStoreEntry] = useState({ name: "" });
	const [Search, setSearch] = useState("");
	const [Type, setType] = useState("add");
	const [By, setBy] = useState("all");
	const [Day, setDay] = useState("all");
	const [Month, setMonth] = useState("all");
	const [Year, setYear] = useState("all");
	const [StoreEntryType, setStoreEntryType] = useState("all");
	const [Item, setItem] = useState("all");
	const [StoreEntryItem, setStoreEntryItem] = useState({});

	//redux dispatch
	const dispatch = useDispatch();

	const User = useSelector((state) => state.auth.user);
	const online = useSelector((state) => state.online.online);
	const ActiveCred = useSelector((state) => state.cred.activeCred);
	const StoreEntries = useSelector(
		(state) => state.storeEntry.storeEntries
	).filter((entry) => parseInt(entry.deleted) === 0);
	const Creds = useSelector((state) => state.cred.creds);

	const Items = useSelector((state) => state.item.items).filter(
		(item) => parseInt(item.deleted) === 0
	);

	const FindUser = (linker) => {
		let cred = Creds.find((cred) => parseInt(cred.linker) == parseInt(linker));

		return cred || { email: "unknown" };
	};

	const SearchStoreEntry = StoreEntries.sort(
		(a, b) => parseInt(a.linker) - parseInt(b.linker)
	)
		.map((entry, index) => {
			const slicedArray = StoreEntries.slice(0, index + 1);
			const cumulativeTotal = slicedArray.reduce((sum, currItem) => {
				if (currItem.itemLinker === entry.itemLinker) {
					return currItem.type === "issued" ||
						currItem.type === "return-received" ||
						currItem.type === "damaged"
						? +sum - +currItem.quantity
						: +sum + +currItem.quantity;
				}
				return sum;
			}, 0);

			return { ...entry, total: cumulativeTotal };
		})
		.filter(
			(storeEntry) =>
				((storeEntry.supplier || "")
					.toLowerCase()
					.search(Search.toLowerCase()) > -1 ||
					storeEntry.details.toLowerCase().search(Search.toLowerCase()) > -1 ||
					Search === "") &&
				parseInt(storeEntry.deleted) === 0 &&
				(storeEntry.type === StoreEntryType || StoreEntryType === "all") &&
				(parseInt(storeEntry.itemLinker) === parseInt(Item) ||
					Item === "all") &&
				(parseInt(
					new Date(parseInt(storeEntry.linker))
						.toLocaleDateString()
						.split("/")[2]
				) === parseInt(Year) ||
					Year === "all") &&
				(parseInt(
					new Date(parseInt(storeEntry.linker))
						.toLocaleDateString()
						.split("/")[0]
				) === parseInt(Month) ||
					Year === "all" ||
					Month === "all") &&
				(parseInt(
					new Date(parseInt(storeEntry.linker))
						.toLocaleDateString()
						.split("/")[1]
				) === parseInt(Day) ||
					Year === "all" ||
					Month === "all" ||
					Day == "all") &&
				(parseInt(storeEntry.credLinker) === parseInt(By) || By === "all")
		)
		.sort((a, b) => parseInt(b.linker) - parseInt(a.linker));

	return (
		<div>
			<div id="store-entries">
				<div className="d-flex justify-content-around mt-2">
					<p className="text-center h6 text-primary text-capitalize">
						{SearchStoreEntry.length} StoreEntries
					</p>{" "}
				</div>

				<table className="table table-sm">
					<thead className="thead-dark">
						<tr>
							<th scope="column">Items</th>
							<th scope="colum">Type</th>
							<th>
								<input
									type={"text"}
									placeholder={`Search StoreEntry`}
									value={Search}
									onChange={(e) => setSearch(e.target.value)}
									className="rounded form-control"
								/>
							</th>
							<th scope="column">Amt</th>
							<th scope="column">QTY</th>
							<th scope="column">Bal</th>
							<th scope="column">Date</th>
							<th scope="column">By</th>
						</tr>
						<tr>
							<th scope="column">
								{" "}
								<select
									className="form-control rounded"
									value={Item}
									onChange={(e) => setItem(e.target.value)}
								>
									<option value={"all"}>All Items</option>
									<PlainList
										list={Items}
										renderOnScroll
										renderItem={(item) => (
											<option key={item.linker} value={item.linker}>
												{item.name}
											</option>
										)}
									/>
								</select>
							</th>
							<th scope="column">
								<select
									className="form-control rounded"
									value={StoreEntryType}
									onChange={(e) => setStoreEntryType(e.target.value)}
								>
									<option value={"all"}>All Types</option>
									<option value={"received"}>Received</option>
									<option value={"return-received"}>Return Received</option>
									<option value={"issued"}>Issued</option>
									<option value={"return-issued"}>Return Issued</option>
									<option value={"damaged"}>Damaged</option>
								</select>
							</th>
							<th scope="column">Ref</th>
							<th scope="column">
								{SearchStoreEntry.filter(
									(storeEntry) => storeEntry.type === "received"
								).reduce((a, b) => +a + +b.amount, 0) -
									SearchStoreEntry.filter(
										(storeEntry) => storeEntry.type === "return-received"
									).reduce((a, b) => +a + +b.amount, 0)}
							</th>

							<th>
								{SearchStoreEntry.filter(
									(storeEntry) =>
										storeEntry.type === "received" ||
										storeEntry.type === "return-issued"
								).reduce((a, b) => +a + +b.quantity, 0) -
									SearchStoreEntry.filter(
										(storeEntry) =>
											storeEntry.type === "issued" ||
											storeEntry.type === "return-received" ||
											storeEntry.type === "damaged"
									).reduce((a, b) => +a + +b.quantity, 0)}
							</th>
							<th colSpan={2}>
								<div className="d-flex">
									<select
										className="form-control rounded"
										value={Year}
										onChange={(e) => setYear(e.target.value)}
									>
										<option value={"all"}>All Yrs</option>
										{[
											...new Set(
												StoreEntries.map(
													(storeEntry) =>
														new Date(parseInt(storeEntry.linker))
															.toLocaleDateString()
															.split("/")[2]
												)
											),
										].map((yr) => (
											<option key={yr} value={yr}>
												{yr}
											</option>
										))}
									</select>
									{Year !== "all" ? (
										<select
											className="form-control rounded"
											value={Month}
											onChange={(e) => setMonth(e.target.value)}
										>
											<option value={"all"}>All Mths</option>
											{[
												...new Set(
													StoreEntries.map(
														(storeEntry) =>
															new Date(parseInt(storeEntry.linker))
																.toLocaleDateString()
																.split("/")[0]
													)
												),
											].map((mth) => (
												<option key={mth} value={mth}>
													{mth}
												</option>
											))}
										</select>
									) : null}
									{Month !== "all" && Year !== "all" ? (
										<select
											className="form-control rounded"
											value={Day}
											onChange={(e) => setDay(e.target.value)}
										>
											<option value={"all"}>All Dates</option>
											{[
												...new Set(
													StoreEntries.map(
														(storeEntry) =>
															new Date(parseInt(storeEntry.linker))
																.toLocaleDateString()
																.split("/")[1]
													)
												),
											].map((day) => (
												<option key={day} value={day}>
													{day}
												</option>
											))}
										</select>
									) : null}
								</div>
							</th>
							<th>
								<select
									className="form-control rounded"
									value={By}
									onChange={(e) => setBy(e.target.value)}
								>
									<option value={"all"}>All Staffs</option>
									<PlainList
										list={[
											...new Set(
												StoreEntries.map((storeEntry) => storeEntry.credLinker)
											),
										]}
										renderOnScroll
										renderItem={(staff) => (
											<option value={staff} key={staff}>
												{FindUser(staff).email}
											</option>
										)}
									/>
								</select>
							</th>
						</tr>
					</thead>
					{SearchStoreEntry.length > 0 ? (
						<tbody>
							<PlainList
								list={SearchStoreEntry}
								renderOnScroll
								renderItem={(storeEntry) => (
									<StoreEntryDisp
										setShowModal={setShowModal}
										ShowModal={ShowModal}
										storeEntry={storeEntry}
										setStoreEntry={setStoreEntry}
										type={Type}
										setType={setType}
										key={storeEntry.linker}
										Items={Items}
										setStoreEntryItem={setStoreEntryItem}
									></StoreEntryDisp>
								)}
							/>
						</tbody>
					) : (
						<NoStoreEntry></NoStoreEntry>
					)}
				</table>
			</div>
			<GeneratePdf id="store-entries" name={"store-entries"}></GeneratePdf>
			<ItemStoreEntry
				setShowModal={setShowModal}
				ShowModal={ShowModal}
				type={Type}
				storeEntry={StoreEntry}
				setStoreEntry={setStoreEntry}
				item={StoreEntryItem}
			></ItemStoreEntry>
		</div>
	);
};

export default StoreEntries;
