import React from "react";

const NoInsurances = () => {
	return (
		<tbody>
			<tr>
				<th>No Insurances</th>

				<td>
					Insurances are payments paid through insurance companies and their
					settlements
				</td>
				<td>
					<p className="text-primary">
						To add insurance payments go to patients update patient
					</p>
				</td>
			</tr>
		</tbody>
	);
};

export default NoInsurances;
