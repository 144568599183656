import React from "react";
import {
	Trash,
	PencilSquare,
	CashCoin,
	HeartPulse,
	Envelope,
} from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

const PatientDisp = ({
	patient,
	setPatient,
	setShowModal,
	setType,
	setSmsModal,
}) => {
	const Navigate = useNavigate();
	const ActiveCred = useSelector((state) => state.cred.activeCred);
	const inst = useSelector((state) => state.inst.activeInst);
	const Wards = useSelector((state) => state.ward.wards);
	const Beds = useSelector((state) => state.bed.beds);
	const Invoices = useSelector((state) => state.invoice.invoices).filter(
		(record) =>
			parseInt(record.patientLinker) === parseInt(patient.linker) &&
			record.deleted == 0
	);
	const Payments = useSelector((state) => state.payment.payments).filter(
		(record) =>
			parseInt(record.patientLinker) === parseInt(patient.linker) &&
			record.deleted == 0
	);

	const GetWard = Wards.find(
		(ward) => parseInt(ward.linker) === parseInt(patient.ward)
	) || { name: "Unknown" };

	const GetBed = Beds.find(
		(bed) => parseInt(bed.linker) === parseInt(patient.bed)
	) || { bedNo: "unknown" };
	return (
		<tr key={patient.index}>
			<td> {patient.patientReg}</td>
			<td>
				<Dropdown variant="transparent">
					<Dropdown.Toggle variant="transparent" className="text-left">
						{patient.firstname}
					</Dropdown.Toggle>
					<Dropdown.Menu variant="transparent">
						<Dropdown.Item>
							<p>
								<strong>Name : </strong>{" "}
								<em>
									{" "}
									{patient.firstname} {patient.lastname} {patient.surname}
								</em>
							</p>
						</Dropdown.Item>
						<Dropdown.Item>
							<p>
								<strong>Age : </strong> <em>{patient.age}</em>
							</p>
						</Dropdown.Item>
						<Dropdown.Item>
							<p>
								<strong>Contact : </strong> <em>{patient.contact}</em>
							</p>
						</Dropdown.Item>
						<Dropdown.Item>
							<p>
								<strong>Email : </strong> <em>{patient.email}</em>
							</p>
						</Dropdown.Item>
						<Dropdown.Item>
							<p>
								<strong>Guardian Name : </strong>{" "}
								<em>{patient.guardianName}</em>
							</p>
						</Dropdown.Item>
						<Dropdown.Item>
							<p>
								<strong>Guardian Contact : </strong>{" "}
								<em>{patient.guardianContact}</em>
							</p>
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			</td>
			<td>{patient.gender}</td>
			<td>{patient.status}</td>
			<td>{GetWard.name}</td>
			<td>{GetBed.bedNo}</td>
			<td>
				{Invoices.reduce((a, b) => +a + +b.amount, 0) -
					Payments.reduce((a, b) => +a + +b.amount, 0)}
			</td>
			<td>{new Date(parseInt(patient.linker)).toLocaleDateString("en-US")}</td>

			<td>
				<Dropdown variant="transparent">
					<Dropdown.Toggle
						variant="transparent"
						className="text-left text-primary btn-link text-wrap"
					>
						Update Patient...
					</Dropdown.Toggle>
					<Dropdown.Menu variant="transparent">
						<Dropdown.Item
							className="text-info mx-1"
							onClick={() => {
								setShowModal(true);
								setType("edit");
								setPatient({ ...patient });
							}}
							style={{ cursor: "pointer" }}
						>
							<PencilSquare /> Edit Patient
						</Dropdown.Item>
						{parseInt(ActiveCred.admin) === 1 ? (
							<Dropdown.Item
								className="text-danger mx-1"
								onClick={() => {
									setShowModal(true);
									setType("delete");
									setPatient({ ...patient });
								}}
								style={{ cursor: "pointer" }}
							>
								<Trash /> Delete Patient
							</Dropdown.Item>
						) : null}
						{parseInt(ActiveCred.admin) === 1 ||
						parseInt(ActiveCred.finance) === 1 ? (
							<Dropdown.Item
								onClick={() => {
									window.scroll(0, 0);
									Navigate(`patient/finance/${patient.linker}`);
								}}
								style={{ cursor: "pointer" }}
								className="mx-1 text-primary"
							>
								<CashCoin /> Bill Pay & Invoice
							</Dropdown.Item>
						) : null}
						{/*		<Dropdown.Item
							onClick={() => {
								window.scroll(0, 0);
								Navigate(`patient/vital/${patient.linker}`);
							}}
							style={{ cursor: "pointer" }}
							className="text-primary mx-1"
						>
							Vital Records
						</Dropdown.Item>*/}
						<Dropdown.Item
							onClick={() => {
								window.scroll(0, 0);
								Navigate(`patient/health/${patient.linker}`);
							}}
							style={{ cursor: "pointer" }}
							className="text-primary mx-1"
						>
							<HeartPulse /> Health Records
						</Dropdown.Item>
						{inst.sub === "Kenya" ? (
							<Dropdown.Item
								className="text-info mx-1"
								onClick={() => {
									setSmsModal(true);

									setPatient({ ...patient });
								}}
								style={{ cursor: "pointer" }}
							>
								<Envelope /> Sms Patient
							</Dropdown.Item>
						) : null}{" "}
					</Dropdown.Menu>
				</Dropdown>
			</td>
		</tr>
	);
};

export default PatientDisp;
