import React, { useState } from "react";
import { ArrowBarLeft, FileEarmarkPlus } from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import VitalCrud from "../Vital/VitalCrud";
import VitalDisp from "../Vital/VitalDisp";
import GeneratePdf from "../../Utils/GeneratePdf";
const PatientVital = () => {
  const params = useParams();
  const Navigate = useNavigate();
  const ActiveCred = useSelector((state) => state.cred.activeCred);
  const Patient = useSelector((state) => state.cred.creds).find(
    (patient) => parseInt(params.patientLinker) === parseInt(patient.linker)
  ) || { linker: params.patientLinker };
  const Vitals = useSelector((state) => state.vital.vitals).filter(
    (record) =>
      record.deleted == 0 &&
      parseInt(record.patientLinker) === parseInt(params.patientLinker)
  );

  const [VitalCrudModal, setVitalCrudModal] = useState(false);
  const [ActiveVital, setActiveVital] = useState({
    patient: Patient,
    patientLinker: Patient.linker,
    id: 0,
    note: "",
    linker: Date.now(),
    status: 0,
    trace: Date.now(),
    live: 0,
    deleted: 0,
    createdAt: 0,
    updatedAt: 0,
  });
  const [Type, setType] = useState("add");
  return (
    <div>
      {" "}
      <div className="d-flex justify-content-around mt-2">
        <button
          className="btn btn-sm btn-outline-secondary"
          type="submit"
          onClick={() => Navigate(-1)}
        >
          <ArrowBarLeft></ArrowBarLeft> Go Back
        </button>
        {parseInt(ActiveCred.admin) === 1 ||
        parseInt(ActiveCred.tutor) === 1 ? (
          <button
            className="btn btn-sm btn-outline-secondary"
            type="submit"
            onClick={() => {
              setVitalCrudModal(true);
              setType("add");
              setActiveVital({
                patient: Patient,
                patientLinker: Patient.linker,
                id: 0,
                note: "",
                linker: Date.now(),
                status: 0,
                trace: Date.now(),
                live: 0,
                deleted: 0,
                createdAt: 0,
                updatedAt: 0,
              });
            }}
          >
            <FileEarmarkPlus></FileEarmarkPlus> Add Vital Record
          </button>
        ) : null}
      </div>{" "}
      <div id="patient-vital-records">
        <p className="h5 text-center">
          {Patient.patientReg} {Patient.firstname} {Patient.lastname}{" "}
          {Patient.surname} Vital Records{" "}
        </p>

        {Vitals.length > 0 ? (
          Vitals.map((vital) => (
            <VitalDisp
              setShowModal={setVitalCrudModal}
              setVital={setActiveVital}
              setType={setType}
              key={vital.linker}
              vital={vital}
            ></VitalDisp>
          ))
        ) : (
          <p className="text-center h5 text-primary">No Vital Records</p>
        )}
      </div>
      <GeneratePdf
        id={"patient-vital-records"}
        name={`${Patient.patientReg}-${Patient.firstname}-${Patient.lastname}-${Patient.surname}-Vital-Records`}
      ></GeneratePdf>
      <VitalCrud
        vital={ActiveVital}
        type={Type}
        ShowModal={VitalCrudModal}
        setShowModal={setVitalCrudModal}
        setVital={setActiveVital}
        patient={Patient}
      ></VitalCrud>
    </div>
  );
};

export default PatientVital;
