import React, { useState, useEffect } from "react";
import WardCrud from "./WardCrud";
import { useDispatch, useSelector } from "react-redux";
import { PlusCircle, Trash, PencilSquare } from "react-bootstrap-icons";
import NoWard from "./NoWard";
import { FetchWards } from "../../../Network/WardApi";
import GeneratePdf from "../../../Utils/GeneratePdf";

const Wards = () => {
  useEffect(() => {
    FetchWards(User.token, ActiveCred.instLinker, dispatch, online);
  }, []);

  const [ShowModal, setShowModal] = useState(false);
  const [Ward, setWard] = useState({ name: "" });
  const [Search, setSearch] = useState("");
  const [Type, setType] = useState("add");

  //redux dispatch
  const dispatch = useDispatch();

  const User = useSelector((state) => state.auth.user);
  const online = useSelector((state) => state.online.online);
  const ActiveCred = useSelector((state) => state.cred.activeCred);
  const Wards = useSelector((state) => state.ward.wards);

  const SearchWard = Wards.filter(
    (ward) =>
      (ward.name.toLowerCase().search(Search.toLowerCase()) > -1 ||
        Search === "") &&
      parseInt(ward.deleted) === 0
  ).sort((a, b) => parseInt(b.linker) - parseInt(a.linker));

  return (
    <div>
      <p className="text-center h6 text-primary text-capitalize">Wards</p>
      <table className="table table-sm" id={"ward"}>
        <thead className="thead-dark">
          <tr>
            <th scope="column" colSpan={2}>
              <input
                type={"text"}
                placeholder={`Search  Ward`}
                value={Search}
                onChange={(e) => setSearch(e.target.value)}
                className="rounded form-control"
              />
            </th>
            <td>
              <button
                className="btn btn-sm btn-primary"
                onClick={() => {
                  setShowModal(true);
                  setType("add");
                  setWard({ name: "", details: "" });
                }}
              >
                <PlusCircle /> Add Ward
              </button>
            </td>
          </tr>
        </thead>
        {SearchWard.length > 0 ? (
          <tbody>
            {SearchWard.map((ward, index) => (
              <tr key={index} className="item">
                <td>{ward.name}</td>
                <td>{ward.details}</td>
                <td>
                  <button
                    className="btn btn-xs btn-outline-info mx-1"
                    onClick={() => {
                      setShowModal(true);
                      setType("edit");
                      setWard({ ...ward });
                    }}
                  >
                    {" "}
                    <PencilSquare style={{ cursor: "pointer" }} />
                  </button>
                  <button
                    className="btn btn-xs btn-outline-danger mx-1"
                    onClick={() => {
                      setShowModal(true);
                      setType("delete");
                      setWard({ ...ward });
                    }}
                  >
                    {" "}
                    <Trash style={{ cursor: "pointer" }} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        ) : (
          <NoWard></NoWard>
        )}
      </table>
      <GeneratePdf id={"ward"} name={"ward"}></GeneratePdf>
      <WardCrud
        setShowModal={setShowModal}
        ShowModal={ShowModal}
        ward={Ward}
        setWard={setWard}
        type={Type}
      ></WardCrud>
    </div>
  );
};

export default Wards;
