import React from "react";
import { Trash, PencilSquare } from "react-bootstrap-icons";

const ServiceDisp = ({ service, setShowModal, setType, setService, Cats }) => {
  const FindCategory = (linker) => {
    let Cat = Cats.find((cat) => parseInt(cat.linker) === parseInt(linker));
    return Cat || { name: "unknown" };
  };

  return (
    <tr className="item">
      <td>{service.name}</td>
      <td>{service.details}</td>
      <td>{service.amount}</td>
      <td>{FindCategory(service.catLinker).name}</td>
      <td>
        <PencilSquare
          onClick={() => {
            setShowModal(true);
            setType("edit");
            setService({ ...service });
          }}
          style={{ cursor: "pointer" }}
          className="text-info mx-2"
        />

        <Trash
          onClick={() => {
            setShowModal(true);
            setType("delete");
            setService({ ...service });
          }}
          style={{ cursor: "pointer" }}
          className="text-danger mx-2"
        />
      </td>
    </tr>
  );
};

export default ServiceDisp;
