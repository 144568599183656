import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RegisterLiveCred, updateLiveCred } from "../../Network/CredApi";

const PatientCrud = (props) => {
  //redux dispatch
  const dispatch = useDispatch();

  const User = useSelector((state) => state.auth.user);
  const ActiveCred = useSelector((state) => state.cred.activeCred);
  const Wards = useSelector((state) => state.ward.wards).filter(
    (ward) => parseInt(ward.deleted) === 0
  );
  const Beds = useSelector((state) => state.bed.beds).filter(
    (bed) => parseInt(bed.deleted) === 0
  );
  const AllPatients = useSelector((state) => state.cred.creds).filter(
    (patient) =>
      patient.credLinker != "0" &&
      parseInt(patient.deleted) === 0 &&
      parseInt(patient.patient) === 1
  );
  const [Loading, setLoading] = useState(false);

  //add patient
  const AddPatient = async () => {
    let linker = Date.now();

    await RegisterLiveCred(
      {
        ...props.patient,
        linker,
        instLinker: ActiveCred.linker,
        credLinker: ActiveCred.linker,
        live: 1,
        trace: linker,
        deleted: 0,
      },
      "patient/add",
      User.token,
      dispatch
    );
  };

  //edit patient
  const EditPatient = async () => {
    let { credLinker, trace, live, deleted } = props.patient;

    trace = Date.now();
    credLinker = ActiveCred.linker;
    deleted = props.type === "delete" ? 1 : deleted;

    await updateLiveCred(
      { ...props.patient, trace, credLinker, deleted },
      "patient/edit",
      User.token,
      dispatch
    );
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (props.type === "add") {
      await AddPatient();
    } else {
      await EditPatient();
    }
    setLoading(false);
    props.setShowModal(false);
  };

  const FilterBeds = Beds.filter(
    (bed) => parseInt(bed.wardLinker) === parseInt(props.patient.ward)
  );

  const BedPatients = (bedLinker) =>
    AllPatients.filter(
      (patient) =>
        parseInt(patient.bed) === parseInt(bedLinker) &&
        patient.status === "inpatient"
    ).length;

  return (
    <div>
      <Modal
        show={props.ShowModal}
        onHide={() => props.setShowModal(false)}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <strong className="text-capitalize text-center">
            {props.type} Patient:{" "}
          </strong>
          <br />
          {props.type === "delete" ? null : (
            <small style={{ fontSize: "16" }}>Fill Whats Applicable</small>
          )}
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={HandleSubmit}>
            <div className="form-group">
              <div className="row">
                <div className="col-md-6">
                  <label className="mb-2 text-capitalize">
                    Patient/ Receipt No:
                  </label>
                  <input
                    className="rounded form-control"
                    placeholder={`Enter Patient/Receipt No`}
                    value={props.patient.patientReg}
                    onChange={(e) =>
                      props.setPatient({
                        ...props.patient,
                        patientReg: e.target.value,
                      })
                    }
                    readOnly={props.type === "delete" ? true : false}
                  />
                  <hr />
                </div>
                <div className="col-md-6">
                  <label className="mb-2 text-capitalize">Surname:</label>
                  <input
                    className="rounded form-control"
                    placeholder={`Enter Surname`}
                    value={props.patient.surname}
                    onChange={(e) =>
                      props.setPatient({
                        ...props.patient,
                        surname: e.target.value,
                      })
                    }
                    readOnly={props.type === "delete" ? true : false}
                  />
                  <hr />
                </div>
                <div className="col-md-6">
                  <label className="mb-2 text-capitalize">FirstName:</label>
                  <input
                    className="rounded form-control"
                    placeholder={`Enter firstname`}
                    value={props.patient.firstname}
                    onChange={(e) =>
                      props.setPatient({
                        ...props.patient,
                        firstname: e.target.value,
                      })
                    }
                    readOnly={props.type === "delete" ? true : false}
                  />
                  <hr />
                </div>
                <div className="col-md-6">
                  <label className="mb-2 text-capitalize">LastName:</label>
                  <input
                    className="rounded form-control"
                    placeholder={`Enter lastname`}
                    value={props.patient.lastname}
                    onChange={(e) =>
                      props.setPatient({
                        ...props.patient,
                        lastname: e.target.value,
                      })
                    }
                    readOnly={props.type === "delete" ? true : false}
                  />
                  <hr />
                </div>
                <div className="col-md-6">
                  <label className="mb-2 text-capitalize">Email:</label>
                  <input
                    className="rounded form-control"
                    placeholder={`Enter email`}
                    value={props.patient.email}
                    onChange={(e) =>
                      props.setPatient({
                        ...props.patient,
                        email: e.target.value.toLowerCase(),
                      })
                    }
                    type="email"
                    readOnly={props.type === "delete" ? true : false}
                  />
                  <hr />
                </div>
                <div className="col-md-6">
                  <label className="mb-2 text-capitalize">Contact:</label>
                  <input
                    className="rounded form-control"
                    placeholder={`Enter contact`}
                    value={props.patient.contact}
                    onChange={(e) =>
                      props.setPatient({
                        ...props.patient,
                        contact: e.target.value,
                      })
                    }
                    readOnly={props.type === "delete" ? true : false}
                  />
                  <hr />
                </div>
                <div className="col-md-6">
                  <label className="mb-2 text-capitalize">Age:</label>
                  <input
                    className="rounded form-control"
                    placeholder={`Enter age`}
                    value={props.patient.age}
                    onChange={(e) =>
                      props.setPatient({
                        ...props.patient,
                        age: e.target.value,
                      })
                    }
                    readOnly={props.type === "delete" ? true : false}
                    type="number"
                  />
                  <hr />
                </div>
                <div className="col-md-6">
                  <label className="mb-2 text-capitalize">Gender:</label>
                  <select
                    onChange={(e) =>
                      props.setPatient({
                        ...props.patient,
                        gender: e.target.value,
                      })
                    }
                    readOnly={props.type === "delete" ? true : false}
                    value={props.patient.gender}
                    className="rounded form-control"
                  >
                    <option value={""}>Select Gender</option>
                    <option value={"male"}>Male</option>
                    <option value={"female"}>Female</option>
                    <option value={"other"}>Others</option>
                  </select>

                  <hr />
                </div>
                <div className="col-md-6">
                  <label className="mb-2 text-capitalize">
                    Admission Type:
                  </label>
                  <select
                    onChange={(e) =>
                      props.setPatient({
                        ...props.patient,
                        status: e.target.value,
                      })
                    }
                    readOnly={props.type === "delete" ? true : false}
                    value={props.patient.status}
                    className="rounded form-control"
                  >
                    <option value={"outpatient"}>OutPatient</option>
                    <option value={"inpatient"}>InPatient</option>
                    {props.type === "edit" ? (
                      <>
                        <option value={"discharged"}>Discharged</option>
                        <option value={"demised"}>Demised</option>
                      </>
                    ) : null}
                  </select>

                  <hr />
                </div>
                {props.patient.status === "inpatient" && Wards.length > 0 ? (
                  <>
                    {" "}
                    <div className="col-md-6">
                      <label className="mb-2 text-capitalize">Ward:</label>
                      <select
                        onChange={(e) =>
                          props.setPatient({
                            ...props.patient,
                            ward: e.target.value,
                          })
                        }
                        readOnly={props.type === "delete" ? true : false}
                        value={props.patient.ward}
                        className="rounded form-control"
                      >
                        <option value={""}>Select Ward</option>
                        {Wards.map((ward) => (
                          <option key={ward.linker} value={ward.linker}>
                            {ward.name}
                          </option>
                        ))}
                      </select>

                      <hr />
                    </div>
                    {props.patient.ward !== "" && FilterBeds.length > 0 ? (
                      <div className="col-md-6">
                        <label className="mb-2 text-capitalize">Bed</label>
                        <select
                          onChange={(e) =>
                            props.setPatient({
                              ...props.patient,
                              bed: e.target.value,
                            })
                          }
                          readOnly={props.type === "delete" ? true : false}
                          value={props.patient.bed}
                          className="rounded form-control"
                        >
                          <option value={"outpatient"}>Select Bed</option>
                          {FilterBeds.map((bed) => (
                            <option key={bed.linker} value={bed.linker}>
                              {bed.bedNo}
                              {" => "}
                              <em style={{ fontSize: "10px", color: "green" }}>
                                {BedPatients(bed.linker)} patients
                              </em>
                            </option>
                          ))}
                        </select>

                        <hr />
                      </div>
                    ) : null}
                  </>
                ) : null}
                <div className="col-md-6">
                  <label className="mb-2 text-capitalize">Guardian Name:</label>
                  <input
                    className="rounded form-control"
                    placeholder={`Enter guardian name`}
                    value={props.patient.guardianName}
                    onChange={(e) =>
                      props.setPatient({
                        ...props.patient,
                        guardianName: e.target.value,
                      })
                    }
                    readOnly={props.type === "delete" ? true : false}
                  />
                  <hr />
                </div>
                <div className="col-md-6">
                  <label className="mb-2 text-capitalize">
                    Guardian Contact:
                  </label>
                  <input
                    className="rounded form-control"
                    placeholder={`Enter guardian contact`}
                    value={props.patient.guardianContact}
                    onChange={(e) =>
                      props.setPatient({
                        ...props.patient,
                        guardianContact: e.target.value,
                      })
                    }
                    readOnly={props.type === "delete" ? true : false}
                  />
                  <hr />
                </div>
              </div>
            </div>
            <hr />
            <div className="d-flex justify-content-around mb-2">
              {Loading ? (
                <span className="spinner-border text-primary"></span>
              ) : props.type === "delete" ? (
                <Button variant="danger" type="submit">
                  Delete
                </Button>
              ) : (
                <Button variant="primary" type="submit">
                  Save
                </Button>
              )}
              <Button
                variant="secondary"
                type="button"
                onClick={() => props.setShowModal(false)}
              >
                Cancel
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PatientCrud;
