import React, { useState, useEffect } from "react";
import BedCrud from "./BedCrud";
import { useDispatch, useSelector } from "react-redux";
import NoBed from "./NoBed";
import BedDisp from "./BedDisp";
import { FetchBeds } from "../../../Network/BedApi";
import { PlainList } from "flatlist-react";
import GeneratePdf from "../../../Utils/GeneratePdf";

const Beds = () => {
  useEffect(() => {
    FetchBeds(User.token, ActiveCred.instLinker, dispatch, online);
  }, []);

  const [ShowModal, setShowModal] = useState(false);
  const [Bed, setBed] = useState({ name: "" });
  const [Search, setSearch] = useState("");
  const [Type, setType] = useState("add");
  const [Ward, setWard] = useState("all");

  //redux dispatch
  const dispatch = useDispatch();

  const User = useSelector((state) => state.auth.user);
  const online = useSelector((state) => state.online.online);
  const ActiveCred = useSelector((state) => state.cred.activeCred);
  const Beds = useSelector((state) => state.bed.beds);

  const Wards = useSelector((state) => state.ward.wards).filter(
    (ward) => parseInt(ward.deleted) === 0
  );

  const SearchBed = Beds.filter(
    (bed) =>
      (bed.bedNo.toLowerCase().search(Search.toLowerCase()) > -1 ||
        Search === "") &&
      parseInt(bed.deleted) === 0 &&
      (parseInt(bed.wardLinker) === parseInt(Ward) || Ward === "all")
  ).sort((a, b) => parseInt(b.linker) - parseInt(a.linker));

  return (
    <div>
      <div className="d-flex justify-content-around mt-2">
        <p className="text-center h6 text-primary text-capitalize">
          {SearchBed.length} Beds
        </p>{" "}
        <button
          className="btn btn-sm btn-outline-secondary"
          onClick={() => {
            setType("add");
            setShowModal(true);
            setBed({
              linker: Date.now(),
              trace: Date.now(),
              bedNo: "",
              wardLinker: "",
              deleted: "0",
            });
          }}
        >
          Add Bed
        </button>
      </div>

      <table className="table table-sm" id="beds">
        <thead className="thead-dark">
          <tr>
            <th scope="column">
              <input
                type={"text"}
                placeholder={`Search Bed`}
                value={Search}
                onChange={(e) => setSearch(e.target.value)}
                className="rounded form-control"
              />
            </th>
            <th scope="column">
              <select
                className="form-control rounded"
                value={Ward}
                onChange={(e) => setWard(e.target.value)}
              >
                <option value={"all"}>All Wards</option>
                {Wards.map((ward, index) => (
                  <option key={index} value={ward.linker}>
                    {ward.name}
                  </option>
                ))}
              </select>
            </th>
          </tr>
        </thead>
        {SearchBed.length > 0 ? (
          <tbody>
            <PlainList
              list={SearchBed}
              renderOnScroll
              renderItem={(bed) => (
                <BedDisp
                  setShowModal={setShowModal}
                  ShowModal={ShowModal}
                  bed={bed}
                  setBed={setBed}
                  type={Type}
                  setType={setType}
                  key={bed.linker}
                  Wards={Wards}
                ></BedDisp>
              )}
            />
          </tbody>
        ) : (
          <NoBed></NoBed>
        )}
      </table>
      <GeneratePdf id={"beds"} name={"beds"}></GeneratePdf>
      <BedCrud
        setShowModal={setShowModal}
        ShowModal={ShowModal}
        bed={Bed}
        setBed={setBed}
        type={Type}
        Wards={Wards}
      ></BedCrud>
    </div>
  );
};

export default Beds;
