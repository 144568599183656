import { createSlice } from "@reduxjs/toolkit";

export const HealthSlice = createSlice({
  name: "health",
  initialState: {
    healths: [],
  },
  reducers: {
    fetchHealths: (state, action) => {
      state.healths = action.payload;
    },
    addHealth: (state, action) => {
      state.healths = [action.payload, ...state.healths];
    },
    updateHealth: (state, action) => {
      let index = state.healths.findIndex(
        (health) => parseInt(health.linker) === parseInt(action.payload.linker)
      );

      let prevHealths = [...state.healths];

      prevHealths.splice(
        index < 0 ? 1 : index,
        index < 0 ? 0 : 1,
        action.payload
      );

      state.healths = prevHealths;
    },
    deleteHealth: (state, action) => {
      let newHealths = state.healths.filter(
        (health) => parseInt(health.linker) !== parseInt(action.payload.linker)
      );
      state.healths = newHealths;
    },
  },
});

export const { fetchHealths, addHealth, updateHealth, deleteHealth } =
  HealthSlice.actions;

export default HealthSlice.reducer;
