import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { addLivePayment } from "../../Network/PaymentApi";

const PatientPayment = (props) => {
  const [Name, setName] = useState("");
  const [Amount, setAmount] = useState("");
  const [Details, setDetails] = useState("");
  const [Mode, setMode] = useState("");
  const [Code, setCode] = useState("");
  const [Status, setStatus] = useState("");
  const [Loading, setLoading] = useState(false);

  //redux dispatch
  const dispatch = useDispatch();

  const User = useSelector((state) => state.auth.user);
  const online = useSelector((state) => state.online.online);
  const ActiveCred = useSelector((state) => state.cred.activeCred);
  const Modes = useSelector((state) => state.mode.modes).filter(
    (payment) => parseInt(payment.deleted) === 0
  );
  const Ins = useSelector((state) => state.insComp.insComps).filter(
    (ins) => parseInt(ins.deleted) === 0
  );

  const ResetInputs = () => {
    setName("");
    setAmount("");
    setDetails("");
    setMode("");
    setCode("");
    setStatus("");
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();
    let linker = Date.now();
    setLoading(true);

    await addLivePayment(
      {
        details: Details,
        linker,
        name: Name,
        amount: Amount,
        mode: Mode,
        code: Code,
        patientLinker: props.patient.linker,
        instLinker: ActiveCred.linker,
        credLinker: ActiveCred.linker,
        live: 1,
        status: Status,
        trace: linker,
        deleted: 0,
      },
      ActiveCred.instLinker,
      User.token,
      dispatch
    );
    setLoading(false);
    props.setPaymentModal(false);

    ResetInputs();
  };

  return (
    <Modal
      show={props.PaymentModal}
      onHide={() => {
        props.setPaymentModal(false);
        ResetInputs();
      }}
      backdrop="static"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Payment {props.patient.patientReg} {props.patient.firstname}{" "}
          {props.patient.lastname} {props.patient.surname}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={HandleSubmit}>
          <div className="row">
            <div className="col-md-12">
              <label> Payment For : </label>
              <input
                className="form-control rounded"
                onChange={(e) => setName(e.target.value)}
                value={Name}
                placeholder="Enter Payment Name"
                required
              />

              <hr />
            </div>
            <div className="col-md-12">
              <label>Payment Amount : </label>
              <input
                className="form-control rounded"
                onChange={(e) => setAmount(e.target.value)}
                value={Amount}
                placeholder="Enter Payment Amount"
                required
                type="number"
              />

              <hr />
            </div>

            <div className="col-md-12">
              <label> Payment Type : </label>
              <select
                className="form-control rounded"
                onChange={(e) => {
                  setStatus(e.target.value);
                  setMode("");
                }}
                value={Status}
                required
              >
                <option value={""}>Select Payment Type</option>
                <option value={"direct"}>Direct Payment</option>
                <option value={"insurance"}>Insurance Payment</option>
              </select>
              <hr />
            </div>

            {Modes.length > 0 && Status === "direct" ? (
              <div className="col-md-12">
                <label> Payment Mode : </label>
                <select
                  className="form-control rounded"
                  onChange={(e) => setMode(e.target.value)}
                  value={Mode}
                  required
                >
                  <option value={""}>Select Payment Mode</option>
                  {Modes.map((mode, index) => (
                    <option value={mode.linker} key={index}>
                      {mode.name}
                    </option>
                  ))}
                </select>
                <hr />
              </div>
            ) : null}
            {Ins.length > 0 && Status === "insurance" ? (
              <div className="col-md-12">
                <label> Insurance Company : </label>
                <select
                  className="form-control rounded"
                  onChange={(e) => setMode(e.target.value)}
                  value={Mode}
                  required
                >
                  <option value={""}>Select Insurance Company</option>
                  {Ins.map((ins, index) => (
                    <option value={ins.linker} key={index}>
                      {ins.name}
                    </option>
                  ))}
                </select>
                <hr />
              </div>
            ) : null}

            {Status !== "" ? (
              <>
                {" "}
                <div className="col-md-12">
                  <label>Payment Details : </label>
                  <textarea
                    className="form-control rounded"
                    onChange={(e) => setDetails(e.target.value)}
                    value={Details}
                    placeholder="Enter Payment Details"
                    required
                  />

                  <hr />
                </div>
                <div className="col-md-12">
                  <label>Transaction/Receipt No : </label>
                  <input
                    className="form-control rounded"
                    onChange={(e) => setCode(e.target.value)}
                    value={Code}
                    placeholder="EnterTransaction/Receipt No"
                    required
                    type="text"
                  />

                  <hr />
                </div>
              </>
            ) : null}
          </div>

          <div className="d-flex justify-content-around mb-2">
            {Loading ? (
              <span className="spinner-border text-primary"></span>
            ) : (
              <Button variant="primary" type="submit">
                Save
              </Button>
            )}

            <Button
              variant="secondary"
              type="button"
              onClick={() => {
                props.setPaymentModal(false);
                ResetInputs();
              }}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default PatientPayment;
