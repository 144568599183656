import React from "react";
import { useParams } from "react-router-dom";

const NoServiceCat = () => {
  const params = useParams();
  return (
    <tbody>
      <tr>
        <td>No Service Categories</td>

        <td>
          These are various service categories offered in your health facility
        </td>
      </tr>
    </tbody>
  );
};

export default NoServiceCat;
