import React from "react";

const NoAppointment = () => {
  return (
    <tbody>
      <tr>
        <th>No Appointments</th>

        <td>
          These are various future service/clinic bookings made by patients.
        </td>
      </tr>
    </tbody>
  );
};

export default NoAppointment;
