import React, { useState, useEffect } from "react";
import ServiceCrud from "./ServiceCrud";
import { useDispatch, useSelector } from "react-redux";
import NoService from "./NoService";
import ServiceDisp from "./ServiceDisp";
import { FetchServices } from "../../../Network/ServiceApi";
import { PlainList } from "flatlist-react";
import GeneratePdf from "../../../Utils/GeneratePdf";

const Services = () => {
  useEffect(() => {
    FetchServices(User.token, ActiveCred.instLinker, dispatch, online);
  }, []);

  const [ShowModal, setShowModal] = useState(false);
  const [Service, setService] = useState({ name: "" });
  const [Search, setSearch] = useState("");
  const [Type, setType] = useState("add");
  const [Cat, setCat] = useState("all");

  //redux dispatch
  const dispatch = useDispatch();

  const User = useSelector((state) => state.auth.user);
  const online = useSelector((state) => state.online.online);
  const ActiveCred = useSelector((state) => state.cred.activeCred);
  const Services = useSelector((state) => state.service.services);

  const Cats = useSelector((state) => state.serviceCat.serviceCats).filter(
    (cat) => parseInt(cat.deleted) === 0
  );

  const SearchService = Services.filter(
    (service) =>
      (service.name.toLowerCase().search(Search.toLowerCase()) > -1 ||
        service.details.toLowerCase().search(Search.toLowerCase()) > -1 ||
        Search === "") &&
      parseInt(service.deleted) === 0 &&
      (parseInt(service.catLinker) === parseInt(Cat) || Cat === "all")
  ).sort((a, b) => parseInt(b.linker) - parseInt(a.linker));

  return (
    <div>
      <div className="d-flex justify-content-around mt-2">
        <p className="text-center h6 text-primary text-capitalize">
          {SearchService.length} Services
        </p>{" "}
        <button
          className="btn btn-sm btn-outline-secondary"
          onClick={() => {
            setType("add");
            setShowModal(true);
            setService({
              linker: Date.now(),
              trace: Date.now(),
              name: "",
              details: "",
              amount: "",
              catLinker: "",
              deleted: "0",
            });
          }}
        >
          Add Service
        </button>
      </div>

      <table className="table table-sm" id="services">
        <thead className="thead-dark">
          <tr>
            <th scope="column">
              <input
                type={"text"}
                placeholder={`Search Service`}
                value={Search}
                onChange={(e) => setSearch(e.target.value)}
                className="rounded form-control"
              />
            </th>
            <th>Details</th>
            <th scope="column">Cost</th>
            <th scope="column">
              <select
                className="form-control rounded"
                value={Cat}
                onChange={(e) => setCat(e.target.value)}
              >
                <option value={"all"}>All Categories</option>
                {Cats.map((cat, index) => (
                  <option key={index} value={cat.linker}>
                    {cat.name}
                  </option>
                ))}
              </select>
            </th>
          </tr>
        </thead>
        {SearchService.length > 0 ? (
          <tbody>
            <PlainList
              list={SearchService}
              renderOnScroll
              renderItem={(service) => (
                <ServiceDisp
                  setShowModal={setShowModal}
                  ShowModal={ShowModal}
                  service={service}
                  setService={setService}
                  type={Type}
                  setType={setType}
                  key={service.linker}
                  Cats={Cats}
                ></ServiceDisp>
              )}
            />
          </tbody>
        ) : (
          <NoService></NoService>
        )}
      </table>
      <GeneratePdf id={"services"} name={"services"}></GeneratePdf>
      <ServiceCrud
        setShowModal={setShowModal}
        ShowModal={ShowModal}
        service={Service}
        setService={setService}
        type={Type}
        Cats={Cats}
      ></ServiceCrud>
    </div>
  );
};

export default Services;
