import { createSlice } from "@reduxjs/toolkit";

export const BedSlice = createSlice({
  name: "bed",
  initialState: {
    beds: [],
  },
  reducers: {
    fetchBeds: (state, action) => {
      state.beds = action.payload;
    },
    addBed: (state, action) => {
      state.beds = [action.payload, ...state.beds];
    },
    updateBed: (state, action) => {
      let index = state.beds.findIndex(
        (bed) => parseInt(bed.linker) === parseInt(action.payload.linker)
      );

      let prevBeds = [...state.beds];

      prevBeds.splice(index < 0 ? 1 : index, index < 0 ? 0 : 1, action.payload);

      state.beds = prevBeds;
    },
    deleteBed: (state, action) => {
      let newBeds = state.beds.filter(
        (bed) => parseInt(bed.linker) !== parseInt(action.payload.linker)
      );
      state.beds = newBeds;
    },
  },
});

export const { fetchBeds, addBed, updateBed, deleteBed } = BedSlice.actions;

export default BedSlice.reducer;
