import { createSlice } from "@reduxjs/toolkit";

export const AppointmentSlice = createSlice({
  name: "appointment",
  initialState: {
    appointments: [],
  },
  reducers: {
    fetchAppointments: (state, action) => {
      state.appointments = action.payload;
    },
    addAppointment: (state, action) => {
      state.appointments = [action.payload, ...state.appointments];
    },
    updateAppointment: (state, action) => {
      let index = state.appointments.findIndex(
        (appointment) =>
          parseInt(appointment.linker) === parseInt(action.payload.linker)
      );

      let prevAppointments = [...state.appointments];

      prevAppointments.splice(
        index < 0 ? 1 : index,
        index < 0 ? 0 : 1,
        action.payload
      );

      state.appointments = prevAppointments;
    },
    deleteAppointment: (state, action) => {
      let newAppointments = state.appointments.filter(
        (appointment) =>
          parseInt(appointment.linker) !== parseInt(action.payload.linker)
      );
      state.appointments = newAppointments;
    },
  },
});

export const {
  fetchAppointments,
  addAppointment,
  updateAppointment,
  deleteAppointment,
} = AppointmentSlice.actions;

export default AppointmentSlice.reducer;
