import React from "react";

const NoService = () => {
  return (
    <tbody>
      <tr>
        <th>No Services</th>

        <td>Here you can add services offered at your health facility</td>
      </tr>
    </tbody>
  );
};

export default NoService;
