import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NoEntry from "./NoEntry";
import { fetchCreds } from "../../../reducers/CredSlice";
import EntryDisp from "./EntryDisp";
import { FetchEntries } from "../../../Network/EntryApi";
import { PlainList } from "flatlist-react";
import ProductEntry from "../Products/ProductEntry";
import GeneratePdf from "../../../Utils/GeneratePdf";

const Entries = () => {
  useEffect(() => {
    FetchEntries(User.token, ActiveCred.instLinker, dispatch, online);
  }, []);

  const [ShowModal, setShowModal] = useState(false);
  const [Entry, setEntry] = useState({ name: "" });
  const [Search, setSearch] = useState("");
  const [Type, setType] = useState("add");
  const [By, setBy] = useState("all");
  const [Day, setDay] = useState("all");
  const [Month, setMonth] = useState("all");
  const [Year, setYear] = useState("all");
  const [EntryType, setEntryType] = useState("all");
  const [Product, setProduct] = useState("all");
  const [EntryProduct, setEntryProduct] = useState({});

  //redux dispatch
  const dispatch = useDispatch();

  const User = useSelector((state) => state.auth.user);
  const online = useSelector((state) => state.online.online);
  const ActiveCred = useSelector((state) => state.cred.activeCred);
  const Entries = useSelector((state) => state.entry.entries).filter(
    (entry) => parseInt(entry.deleted) === 0
  );
  const Creds = useSelector((state) => state.cred.creds);

  const Products = useSelector((state) => state.product.products).filter(
    (product) => parseInt(product.deleted) === 0
  );

  const FindUser = (linker) => {
    let cred = Creds.find((cred) => parseInt(cred.linker) == parseInt(linker));

    return cred || { email: "unknown" };
  };

  const SearchEntry = Entries.sort(
    (a, b) => parseInt(a.linker) - parseInt(b.linker)
  )
    .map((entry, index) => {
      const slicedArray = Entries.slice(0, index + 1);
      const cumulativeTotal = slicedArray.reduce((sum, currItem) => {
        if (currItem.productLinker === entry.productLinker) {
          return currItem.type === "sale" ||
            currItem.type === "return-purchase" ||
            currItem.type === "damaged"
            ? +sum - +currItem.quantity
            : +sum + +currItem.quantity;
        }
        return sum;
      }, 0);

      return { ...entry, total: cumulativeTotal };
    })
    .filter(
      (entry) =>
        ((entry.supplier || "").toLowerCase().search(Search.toLowerCase()) >
          -1 ||
          entry.details.toLowerCase().search(Search.toLowerCase()) > -1 ||
          Search === "") &&
        parseInt(entry.deleted) === 0 &&
        (entry.type === EntryType || EntryType === "all") &&
        (parseInt(entry.productLinker) === parseInt(Product) ||
          Product === "all") &&
        (parseInt(
          new Date(parseInt(entry.linker)).toLocaleDateString().split("/")[2]
        ) === parseInt(Year) ||
          Year === "all") &&
        (parseInt(
          new Date(parseInt(entry.linker)).toLocaleDateString().split("/")[0]
        ) === parseInt(Month) ||
          Year === "all" ||
          Month === "all") &&
        (parseInt(
          new Date(parseInt(entry.linker)).toLocaleDateString().split("/")[1]
        ) === parseInt(Day) ||
          Year === "all" ||
          Month === "all" ||
          Day == "all") &&
        (parseInt(entry.credLinker) === parseInt(By) || By === "all")
    )
    .sort((a, b) => parseInt(b.linker) - parseInt(a.linker));

  return (
    <div>
      <div id="pham-entries">
        <div className="d-flex justify-content-around mt-2">
          <p className="text-center h6 text-primary text-capitalize">
            {SearchEntry.length} Entries
          </p>{" "}
        </div>

        <table className="table table-sm">
          <thead className="thead-dark">
            <tr>
              <th scope="column">Products</th>
              <th scope="colum">Type</th>
              <th colSpan={2}>
                <input
                  type={"text"}
                  placeholder={`Search Entry`}
                  value={Search}
                  onChange={(e) => setSearch(e.target.value)}
                  className="rounded form-control"
                />
              </th>
              <th scope="column">AMT</th>
              <th scope="column">QTY</th>
              <th scope="column">BAL</th>
              <th scope="column">Date</th>
              <th scope="column">By</th>
            </tr>
            <tr>
              <th scope="column">
                {" "}
                <select
                  className="form-control rounded"
                  value={Product}
                  onChange={(e) => setProduct(e.target.value)}
                >
                  <option value={"all"}>All Products</option>
                  <PlainList
                    list={Products}
                    renderOnScroll
                    renderItem={(product) => (
                      <option key={product.linker} value={product.linker}>
                        {product.name}
                      </option>
                    )}
                  />
                </select>
              </th>
              <th scope="column">
                <select
                  className="form-control rounded"
                  value={EntryType}
                  onChange={(e) => setEntryType(e.target.value)}
                >
                  <option value={"all"}>All Types</option>
                  <option value={"purchase"}>Purchase</option>
                  <option value={"return-sale"}>Return Sale</option>
                  <option value={"sale"}>Sale</option>
                  <option value={"return-purchase"}>Return Purchase</option>
                  <option value={"damaged"}>Damaged</option>
                </select>
              </th>
              <th scope="column">Ref</th>
              <th scope="column">Details</th>
              <th>{SearchEntry.reduce((a, b) => +a + +b.amount, 0)}</th>
              <th>
                {SearchEntry.filter(
                  (entry) =>
                    entry.type === "purchase" || entry.type === "return-sale"
                ).reduce((a, b) => +a + +b.quantity, 0) -
                  SearchEntry.filter(
                    (entry) =>
                      entry.type === "sale" ||
                      entry.type === "return-purchase" ||
                      entry.type === "damaged"
                  ).reduce((a, b) => +a + +b.quantity, 0)}
              </th>
              <th colSpan={2}>
                <div className="d-flex">
                  <select
                    className="form-control rounded"
                    value={Year}
                    onChange={(e) => setYear(e.target.value)}
                  >
                    <option value={"all"}>All Yrs</option>
                    {[
                      ...new Set(
                        Entries.map(
                          (entry) =>
                            new Date(parseInt(entry.linker))
                              .toLocaleDateString()
                              .split("/")[2]
                        )
                      ),
                    ].map((yr) => (
                      <option key={yr} value={yr}>
                        {yr}
                      </option>
                    ))}
                  </select>
                  {Year !== "all" ? (
                    <select
                      className="form-control rounded"
                      value={Month}
                      onChange={(e) => setMonth(e.target.value)}
                    >
                      <option value={"all"}>All Mths</option>
                      {[
                        ...new Set(
                          Entries.map(
                            (entry) =>
                              new Date(parseInt(entry.linker))
                                .toLocaleDateString()
                                .split("/")[0]
                          )
                        ),
                      ].map((mth) => (
                        <option key={mth} value={mth}>
                          {mth}
                        </option>
                      ))}
                    </select>
                  ) : null}
                  {Month !== "all" && Year !== "all" ? (
                    <select
                      className="form-control rounded"
                      value={Day}
                      onChange={(e) => setDay(e.target.value)}
                    >
                      <option value={"all"}>All Dates</option>
                      {[
                        ...new Set(
                          Entries.map(
                            (entry) =>
                              new Date(parseInt(entry.linker))
                                .toLocaleDateString()
                                .split("/")[1]
                          )
                        ),
                      ].map((day) => (
                        <option key={day} value={day}>
                          {day}
                        </option>
                      ))}
                    </select>
                  ) : null}
                </div>
              </th>
              <th>
                <select
                  className="form-control rounded"
                  value={By}
                  onChange={(e) => setBy(e.target.value)}
                >
                  <option value={"all"}>All Staffs</option>
                  <PlainList
                    list={[
                      ...new Set(Entries.map((entry) => entry.credLinker)),
                    ]}
                    renderOnScroll
                    renderItem={(staff) => (
                      <option value={staff} key={staff}>
                        {FindUser(staff).email}
                      </option>
                    )}
                  />
                </select>
              </th>
            </tr>
          </thead>
          {SearchEntry.length > 0 ? (
            <tbody>
              <PlainList
                list={SearchEntry}
                renderOnScroll
                renderItem={(entry) => (
                  <EntryDisp
                    setShowModal={setShowModal}
                    ShowModal={ShowModal}
                    entry={entry}
                    setEntry={setEntry}
                    type={Type}
                    setType={setType}
                    key={entry.linker}
                    Products={Products}
                    setEntryProduct={setEntryProduct}
                  ></EntryDisp>
                )}
              />
            </tbody>
          ) : (
            <NoEntry></NoEntry>
          )}
        </table>
      </div>
      <GeneratePdf id="pham-entries" name={"pharmacy-entries"}></GeneratePdf>
      <ProductEntry
        setShowModal={setShowModal}
        ShowModal={ShowModal}
        type={Type}
        entry={Entry}
        setEntry={setEntry}
        product={EntryProduct}
      ></ProductEntry>
    </div>
  );
};

export default Entries;
