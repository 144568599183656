import React, { useState } from "react";
import { ArrowBarLeft, FileEarmarkPlus } from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import HealthCrud from "../Health/HealthCrud";
import HealthDisp from "../Health/HealthDisp";
import GeneratePdf from "../../Utils/GeneratePdf";
import LetterHead from "../../components/LetterHead";
const PatientHealth = () => {
	const params = useParams();
	const Navigate = useNavigate();
	const ActiveCred = useSelector((state) => state.cred.activeCred);
	const Patient = useSelector((state) => state.cred.creds).find(
		(patient) => parseInt(params.patientLinker) === parseInt(patient.linker)
	) || { linker: params.patientLinker };
	const Healths = useSelector((state) => state.health.healths).filter(
		(record) =>
			record.deleted == 0 &&
			parseInt(record.patientLinker) === parseInt(params.patientLinker)
	);

	const [HealthCrudModal, setHealthCrudModal] = useState(false);
	const [ActiveHealth, setActiveHealth] = useState({
		patient: Patient,
		patientLinker: Patient.linker,
		id: 0,
		note: "",
		linker: Date.now(),
		status: 0,
		trace: Date.now(),
		live: 0,
		deleted: 0,
		createdAt: 0,
		updatedAt: 0,
	});
	const [Type, setType] = useState("add");
	return (
		<div>
			{" "}
			<div className="d-flex justify-content-around mt-2">
				<button
					className="btn btn-sm btn-outline-secondary"
					type="submit"
					onClick={() => Navigate(-1)}
				>
					<ArrowBarLeft></ArrowBarLeft> Go Back
				</button>
				{parseInt(ActiveCred.admin) === 1 ||
				parseInt(ActiveCred.tutor) === 1 ? (
					<button
						className="btn btn-sm btn-outline-secondary"
						type="submit"
						onClick={() => {
							setHealthCrudModal(true);
							setType("add");
							setActiveHealth({
								patient: Patient,
								patientLinker: Patient.linker,
								id: 0,
								note: "",
								linker: Date.now(),
								status: 0,
								trace: Date.now(),
								live: 0,
								deleted: 0,
								createdAt: 0,
								updatedAt: 0,
							});
						}}
					>
						<FileEarmarkPlus></FileEarmarkPlus> Add Health Record
					</button>
				) : null}
			</div>{" "}
			<div id="patient-health-records">
				<LetterHead></LetterHead>
				<p className="h5 text-center">
					{Patient.patientReg} {Patient.firstname} {Patient.lastname}{" "}
					{Patient.surname}
					<br /> Health Records
				</p>

				{Healths.length > 0 ? (
					Healths.map((health) => (
						<HealthDisp
							setShowModal={setHealthCrudModal}
							setHealth={setActiveHealth}
							setType={setType}
							key={health.linker}
							health={health}
						></HealthDisp>
					))
				) : (
					<p className="text-center h5 text-primary">No Health Records</p>
				)}
			</div>
			<GeneratePdf
				id={"patient-health-records"}
				name={`${Patient.patientReg}-${Patient.firstname}-${Patient.lastname}-${Patient.surname}-Health-Records`}
			></GeneratePdf>
			<HealthCrud
				health={ActiveHealth}
				type={Type}
				ShowModal={HealthCrudModal}
				setShowModal={setHealthCrudModal}
				setHealth={setActiveHealth}
				patient={Patient}
			></HealthCrud>
		</div>
	);
};

export default PatientHealth;
