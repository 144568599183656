import React from "react";

const NoInvoices = () => {
	return (
		<tbody>
			<tr>
				<th>No Invoices</th>

				<td>
					Invoices are financial obligations that patients owe the institution
					eg consultation fee, admission fee etc
				</td>
				<td>
					<p className="text-primary">
						To add invoices go to patients update patient
					</p>
				</td>
			</tr>
		</tbody>
	);
};

export default NoInvoices;
