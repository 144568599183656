import { createSlice } from "@reduxjs/toolkit";

export const VitalSlice = createSlice({
  name: "vital",
  initialState: {
    vitals: [],
  },
  reducers: {
    fetchVitals: (state, action) => {
      state.vitals = action.payload;
    },
    addVital: (state, action) => {
      state.vitals = [action.payload, ...state.vitals];
    },
    updateVital: (state, action) => {
      let index = state.vitals.findIndex(
        (vital) => parseInt(vital.linker) === parseInt(action.payload.linker)
      );

      let prevVitals = [...state.vitals];

      prevVitals.splice(
        index < 0 ? 1 : index,
        index < 0 ? 0 : 1,
        action.payload
      );

      state.vitals = prevVitals;
    },
    deleteVital: (state, action) => {
      let newVitals = state.vitals.filter(
        (vital) => parseInt(vital.linker) !== parseInt(action.payload.linker)
      );
      state.vitals = newVitals;
    },
  },
});

export const { fetchVitals, addVital, updateVital, deleteVital } =
  VitalSlice.actions;

export default VitalSlice.reducer;
