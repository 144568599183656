import React from "react";

const NoExpense = () => {
  return (
    <tbody>
      <tr>
        <th>No Beds</th>

        <td>Beds available in your health facility wards</td>
      </tr>
    </tbody>
  );
};

export default NoExpense;
