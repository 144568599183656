import React from "react";
import {
  Trash,
  PencilSquare,
  PlusCircle,
  PatchMinus,
} from "react-bootstrap-icons";

const ProductDisp = ({
  product,
  setShowModal,
  setType,
  setProduct,
  Cats,
  setEntry,
  setEntryModal,
  setEntryType,
  Entries,
}) => {
  const FindCategory = (linker) => {
    let Cat = Cats.find((cat) => parseInt(cat.linker) === parseInt(linker));
    return Cat || { name: "unknown" };
  };

  return (
    <tr className="item">
      <td>{product.name}</td>
      <td>{product.details}</td>
      <td>{product.amount}</td>
      <td>
        {Entries.filter(
          (entry) => entry.type === "purchase" || entry.type === "return-sale"
        ).reduce((a, b) => +a + +b.quantity, 0) -
          Entries.filter(
            (entry) =>
              entry.type === "sale" ||
              entry.type === "return-purchase" ||
              entry.type === "damaged"
          ).reduce((a, b) => +a + +b.quantity, 0)}
      </td>
      <td>{FindCategory(product.catLinker).name}</td>
      <td>
        <PlusCircle
          onClick={() => {
            setEntryModal(true);
            setEntryType("add");
            setEntry({
              type: "",
              quantity: "",
              cost: "",
              details: "",
              supplier: "",
              productLinker: product.linker,
              live: 0,
              deleted: 0,
              trace: Date.now(),
              linker: Date.now(),
            });
            setProduct({ ...product });
          }}
          style={{ cursor: "pointer" }}
          className="text-primary mx-2"
        />
        <PatchMinus
          onClick={() => {
            setEntryModal(true);
            setEntryType("subtract");
            setEntry({
              type: "",
              quantity: "",
              cost: "",
              details: "",
              supplier: "",
              productLinker: product.linker,
              live: 0,
              deleted: 0,
              trace: Date.now(),
              linker: Date.now(),
            });
            setProduct({ ...product });
          }}
          style={{ cursor: "pointer" }}
          className="text-primary mx-2"
        />
        <PencilSquare
          onClick={() => {
            setShowModal(true);
            setType("edit");
            setProduct({ ...product });
          }}
          style={{ cursor: "pointer" }}
          className="text-info mx-2"
        />

        <Trash
          onClick={() => {
            setShowModal(true);
            setType("delete");
            setProduct({ ...product });
          }}
          style={{ cursor: "pointer" }}
          className="text-danger mx-2"
        />
      </td>
    </tr>
  );
};

export default ProductDisp;
