import React, { useEffect, useState } from "react";
import { FetchMpesaPayments } from "../../../../../Network/MpesaApi";
import { useDispatch, useSelector } from "react-redux";
import { PlainList } from "flatlist-react";
import MpesaPayment from "./MpesaPayment";

const MpesaPayments = () => {
	useEffect(() => {
		FetchMpesaPayments(User.token, ActiveCred.instLinker, dispatch, online);
	}, []);
	const [Search, setSearch] = useState("");
	const User = useSelector((state) => state.auth.user);
	const online = useSelector((state) => state.online.online);
	const ActiveCred = useSelector((state) => state.cred.activeCred);
	const Payments = useSelector((state) => state.payment.payments)
		.filter((payment) => parseInt(payment.deleted) === 0)
		.sort((a, b) => b.linker - a.linker);
	const OnlinePayments = useSelector(
		(state) => state.mpesaPayment.mpesaPayments
	).filter(
		(onlinePayment) =>
			(onlinePayment.transRef || "")
				.toLowerCase()
				.search(Search.toLowerCase()) > -1 ||
			(onlinePayment.transSender || "")
				.toLowerCase()
				.search(Search.toLowerCase()) > -1 ||
			(onlinePayment.transContact || "")
				.toLowerCase()
				.search(Search.toLowerCase()) > -1 ||
			(onlinePayment.transId || "").toLowerCase().search(Search.toLowerCase()) >
				-1 ||
			(
				new Date(parseInt(onlinePayment.linker)).toLocaleDateString("en-US") ||
				""
			)
				.toLowerCase()
				.search(Search.toLowerCase()) > -1 ||
			Search === ""
	);

	//redux dispatch
	const dispatch = useDispatch();

	return (
		<div>
			<table className="table table-sm">
				<thead>
					<tr>
						<th colSpan={5}>
							{" "}
							<input
								type={"text"}
								placeholder={`Search By Receipt/Patient No, Name, Phone, Code, Date`}
								value={Search}
								onChange={(e) => setSearch(e.target.value)}
								className="rounded-pill form-control"
							/>
						</th>
						<th>Amt</th>
						<th>Paid</th>
						<th>Bal</th>
					</tr>
					<tr>
						<th>Receipt/Patient No</th>
						<th>Name</th>
						<th>Phone</th>

						<th>Code</th>
						<th>Date</th>
						<th>{OnlinePayments.reduce((a, b) => +a + +b.transAmount, 0)}</th>
						<th>
							{Payments.filter((payment) =>
								OnlinePayments.some(
									(onlinePayment) =>
										onlinePayment.transId.toLowerCase() ===
										payment.code.toLowerCase()
								)
							).reduce((a, b) => +a + +b.amount, 0)}
						</th>
						<th>
							{OnlinePayments.reduce((a, b) => +a + +b.transAmount, 0) -
								Payments.filter((payment) =>
									OnlinePayments.some(
										(onlinePayment) =>
											onlinePayment.transId.toLowerCase() ===
											payment.code.toLowerCase()
									)
								).reduce((a, b) => +a + +b.amount, 0)}
						</th>
					</tr>
				</thead>
				<tbody>
					{
						<PlainList
							list={OnlinePayments}
							renderOnScroll
							renderItem={(onlinePayment) => (
								<MpesaPayment onlinePayment={onlinePayment}></MpesaPayment>
							)}
						/>
					}
				</tbody>
			</table>
		</div>
	);
};

export default MpesaPayments;
