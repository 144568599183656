import React, { useState, useEffect } from "react";
import { Envelope, PersonAdd } from "react-bootstrap-icons";
import PatientCrud from "./PatientCrud";
import { useDispatch, useSelector } from "react-redux";
import { FetchPatients } from "../../Network/CredApi";
import { PlainList } from "flatlist-react";
import GeneratePdf from "../../Utils/GeneratePdf";
import PatientDisp from "./PatientDisp";
import SingleSms from "../Admin/Sms/SingleSms";
import { useNavigate } from "react-router-dom";

const Patients = () => {
	useEffect(() => {
		FetchPatients(ActiveCred.instLinker, User.token, dispatch, online);
	}, []);

	//redux dispatch
	const dispatch = useDispatch();
	const Navigate = useNavigate();
	const User = useSelector((state) => state.auth.user);
	const online = useSelector((state) => state.online.online);
	const ActiveCred = useSelector((state) => state.cred.activeCred);
	const inst = useSelector((state) => state.inst.activeInst);
	const Wards = useSelector((state) => state.ward.wards).filter(
		(ward) => parseInt(ward.deleted) === 0
	);
	const Beds = useSelector((state) => state.bed.beds).filter(
		(bed) => parseInt(bed.deleted) === 0
	);
	const [Gender, setGender] = useState("all");
	const [Status, setStatus] = useState("all");
	const [Ward, setWard] = useState("all");
	const [Bed, setBed] = useState("all");
	const [Day, setDay] = useState("all");
	const [Month, setMonth] = useState("all");
	const [Year, setYear] = useState("all");

	const [Search, setSearch] = useState("");
	const [ShowModal, setShowModal] = useState(false);
	const [SmsModal, setSmsModal] = useState(false);
	const [Patient, setPatient] = useState({
		firstname: "",
		lastname: "",
		surname: "",
		patientReg: "",
		contact: "",
		email: "",
		admin: "",
		medical: "",
		finance: "",
	});
	const [Type, setType] = useState("add");

	const AllPatients = useSelector((state) => state.cred.creds).filter(
		(patient) =>
			patient.credLinker != "0" &&
			parseInt(patient.deleted) === 0 &&
			parseInt(patient.patient) === 1
	);

	const Patients = useSelector((state) => state.cred.creds)
		.filter(
			(patient) =>
				patient.credLinker != "0" &&
				parseInt(patient.deleted) === 0 &&
				parseInt(patient.patient) === 1 &&
				(patient.gender === Gender || Gender === "all") &&
				(patient.status === Status || Status === "all") &&
				(parseInt(patient.ward) === parseInt(Ward) || Ward === "all") &&
				(parseInt(patient.bed) === parseInt(Bed) || Bed === "all") &&
				(parseInt(
					new Date(parseInt(patient.linker)).toLocaleDateString().split("/")[2]
				) === parseInt(Year) ||
					Year === "all") &&
				(parseInt(
					new Date(parseInt(patient.linker)).toLocaleDateString().split("/")[0]
				) === parseInt(Month) ||
					Year === "all" ||
					Month === "all") &&
				(parseInt(
					new Date(parseInt(patient.linker)).toLocaleDateString().split("/")[1]
				) === parseInt(Day) ||
					Year === "all" ||
					Month === "all" ||
					Day == "all") &&
				((patient.patientReg || "").toLowerCase().search(Search.toLowerCase()) >
					-1 ||
					(patient.firstname || "").toLowerCase().search(Search.toLowerCase()) >
						-1 ||
					(patient.lastname || "").toLowerCase().search(Search.toLowerCase()) >
						-1 ||
					(patient.surname || "").toLowerCase().search(Search.toLowerCase()) >
						-1 ||
					(patient.email || "").toLowerCase().search(Search.toLowerCase()) >
						-1 ||
					(patient.contact || "").toLowerCase().search(Search.toLowerCase()) >
						-1 ||
					Search === "") &&
				parseInt(patient.linker) !== parseInt(inst.linker)
		)
		.sort((a, b) => parseInt(b.linker) - parseInt(a.linker));

	const Invoices = useSelector((state) => state.invoice.invoices).filter(
		(record) => record.deleted == 0
	);
	const Payments = useSelector((state) => state.payment.payments).filter(
		(record) => record.deleted == 0
	);

	return (
		<div>
			<div className="d-flex justify-content-around mt-2">
				<p className="text-center h6 text-primary text-capitalize">
					{Patients.length} Patients
				</p>
				<button
					className="btn btn-sm btn-primary"
					onClick={() => {
						setShowModal(true);
						setType("add");
						setPatient({
							firstname: "",
							lastname: "",
							surname: "",
							patientReg: "",
							contact: "",
							email: "",
							status: "outpatient",
							age: "",
							gender: "",
							ward: "",
							bed: "",
							patient: 1,
						});
					}}
				>
					<PersonAdd /> Register Patient
				</button>
				{inst.sub === "Kenya" ? (
					<button
						className="btn btn-sm btn-info"
						onClick={() => {
							Navigate(`/${inst.name}/bulk-sms`);
						}}
					>
						<Envelope /> Sms Patients
					</button>
				) : null}{" "}
			</div>

			<table className="table table-sm" id="patients">
				<thead className="thead-dark">
					<tr>
						<td colSpan={2}>
							<input
								type={"text"}
								placeholder={`Search Patient`}
								value={Search}
								onChange={(e) => setSearch(e.target.value)}
								className="rounded form-control"
							/>
						</td>

						<td>
							<select
								className="form-control rounded"
								value={Gender}
								onChange={(e) => setGender(e.target.value)}
							>
								<option value={"all"}>All Genders</option>
								<option value={"male"}>Males</option>
								<option value={"female"}>Females</option>
								<option value={"other"}>Others</option>
							</select>
						</td>
						<td>
							{" "}
							<select
								className="form-control rounded"
								value={Status}
								onChange={(e) => setStatus(e.target.value)}
							>
								<option value={"all"}>All Status</option>
								<option value={"inpatient"}>In Patient</option>
								<option value={"outpatient"}>Out Patient</option>
								<option value={"discharged"}>Discharged</option>
								<option value={"demise"}>Demise</option>
							</select>
						</td>
						<td>
							{" "}
							<select
								className="form-control rounded"
								value={Ward}
								onChange={(e) => setWard(e.target.value)}
							>
								<option value={"all"}>All Wards</option>
								{Wards.map((ward) => (
									<option value={ward.linker} key={ward.linker}>
										{ward.name}
									</option>
								))}
							</select>
						</td>
						<td>
							{" "}
							<select
								className="form-control rounded"
								value={Bed}
								onChange={(e) => setBed(e.target.value)}
							>
								<option value={"all"}>All Beds</option>
								{Beds.filter(
									(bed) =>
										parseInt(bed.wardLinker) === parseInt(Ward) ||
										Ward === "all"
								).map((bed) => (
									<option value={bed.linker} key={bed.linker}>
										{bed.bedNo}
									</option>
								))}
							</select>
						</td>
						<td>
							<small>
								{" "}
								Bal:
								{Invoices.filter((invoice) =>
									Patients.some(
										(patient) =>
											parseInt(patient.linker) ===
											parseInt(invoice.patientLinker)
									)
								).reduce((a, b) => +a + +b.amount, 0) -
									Payments.filter((payment) =>
										Patients.some(
											(patient) =>
												parseInt(patient.linker) ===
												parseInt(payment.patientLinker)
										)
									).reduce((a, b) => +a + +b.amount, 0)}
							</small>
						</td>
						<td colSpan={2}>
							{" "}
							<div className="d-flex">
								{" "}
								<select
									className="form-control rounded"
									value={Year}
									onChange={(e) => setYear(e.target.value)}
								>
									<option value={"all"}>All Yrs</option>
									{[
										...new Set(
											AllPatients.map(
												(patient) =>
													new Date(parseInt(patient.linker))
														.toLocaleDateString()
														.split("/")[2]
											)
										),
									].map((yr) => (
										<option key={yr} value={yr}>
											{yr}
										</option>
									))}
								</select>
								{Year !== "all" ? (
									<select
										value={Month}
										onChange={(e) => setMonth(e.target.value)}
										className="form-control rounded"
									>
										<option value={"all"}>All Mths</option>
										{[
											...new Set(
												AllPatients.map(
													(patient) =>
														new Date(parseInt(patient.linker))
															.toLocaleDateString()
															.split("/")[0]
												)
											),
										].map((mth) => (
											<option key={mth} value={mth}>
												{mth}
											</option>
										))}
									</select>
								) : null}
								{Month !== "all" && Year !== "all" ? (
									<select
										className="form-control rounded"
										value={Day}
										onChange={(e) => setDay(e.target.value)}
									>
										<option value={"all"}>All Dates</option>
										{[
											...new Set(
												AllPatients.map(
													(patient) =>
														new Date(parseInt(patient.linker))
															.toLocaleDateString()
															.split("/")[1]
												)
											),
										].map((day) => (
											<option key={day} value={day}>
												{day}
											</option>
										))}
									</select>
								) : null}
							</div>
						</td>
					</tr>
				</thead>
				<tbody>
					<PlainList
						list={Patients}
						renderOnScroll
						renderItem={(patient) => (
							<PatientDisp
								patient={patient}
								setPatient={setPatient}
								setType={setType}
								setShowModal={setShowModal}
								setSmsModal={setSmsModal}
							></PatientDisp>
						)}
					/>
				</tbody>
			</table>
			<PatientCrud
				setShowModal={setShowModal}
				ShowModal={ShowModal}
				patient={Patient}
				setPatient={setPatient}
				type={Type}
			></PatientCrud>
			<SingleSms
				Patient={Patient}
				setSmsModal={setSmsModal}
				SmsModal={SmsModal}
			></SingleSms>
			<div className="d-flex justify-content-center">
				<GeneratePdf id={"patients"} name={`patients`}></GeneratePdf>
			</div>
		</div>
	);
};

export default Patients;
