import React from "react";
import { useParams } from "react-router-dom";

const NoPharmacyCat = () => {
  const params = useParams();
  return (
    <tbody>
      <tr>
        <td>No Pharmacy Categories</td>

        <td>
          These are various Pharmacy categories offered in your health facility
          Pharmacy
        </td>
      </tr>
    </tbody>
  );
};

export default NoPharmacyCat;
